import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';
import './CreateResultManagementDetails.scss';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  Box,
  Stack,
  Paper,
  TableContainer,
  Container,
} from '@mui/material';
import {
  ListStudentsSectionApi,
  UpdateAddMarksApi,
  ListAddMarksApi,
} from '../../services/apis';
import { useAuth } from '../../services/authContext';

const CreateResultManagementDetails = (props) => {
  const [listOfUsers, setListOfUsers] = useState([]);
  const [listOfMarks, setListOfMarks] = useState([]);
  const [localMarks, setLocalMarks] = useState({}); // Local state for unmatched rows
  const [filter, setFilter] = useState('');
  const { loginStatus, setLoginStatus } = useAuth();
  const [listApiFlag, setListApiFlag] = useState(true);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {

    if (
      listApiFlag &&
      loginStatus.user &&
      (loginStatus.user.staff_type.toLowerCase() === 'admin' ||
        loginStatus.user.staff_type.toLowerCase() === 'teacher')
    ) {
      const fetchListStudents = async () => {
        try {
          const response = await ListStudentsSectionApi({
            id: JSON.parse(loginStatus.user.id),
            section: props.examDetails.section,
          });
          setListOfUsers(response);
          setListApiFlag(false);
          console.log(response)
        } catch (error) {
          console.error('Error fetching users:', error);
          setListApiFlag(false);
        }
      };

      const fetchListMarks = async () => {
        // console.log(props.examDetails)
        try {
          const response = await ListAddMarksApi({
            id: JSON.parse(loginStatus.user.id),
            exam_id: props.examDetails.id,
            subject_id: props.examDetails.subject_id,
            section_id: props.examDetails.section,
            student_id: props.examDetails.student_id,
          });
          setListOfMarks(response);
          setListApiFlag(false);
        } catch (error) {
          console.error('Error fetching marks:', error);
          setListApiFlag(false);
        }
      };

      fetchListStudents();
      fetchListMarks();
    }
  }, [loginStatus, listApiFlag, props.examDetails.section, props.examDetails.id]);

  const HandleUpdateMarks = (marks, markType, row) => {
    const isExistingMark = listOfMarks.some((mark) => mark.student === row.id);

    if (isExistingMark) {
      // Update existing entry in `listOfMarks`
      setListOfMarks((prevMarks) =>
        prevMarks.map((mark) =>
          mark.student === row.id
            ? { ...mark, [markType]: marks } // Update `theory` or `practical`
            : mark
        )
      );
    } else {
      // Update local state for unmatched rows
      setLocalMarks((prevLocalMarks) => ({
        ...prevLocalMarks,
        [row.id]: {
          ...(prevLocalMarks[row.id] || {}),
          [markType]: marks,
        },
      }));
    }

    // Debounce API call
    if (debounceTimeoutRef.current) clearTimeout(debounceTimeoutRef.current);

    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        console.log('API Request', row, props.examDetails);
        const response = await UpdateAddMarksApi(
          JSON.stringify({
            id: JSON.parse(row.id),
            markType,
            marks,
            subject: JSON.parse(props.examDetails.subject_id),
            exam: JSON.parse(props.examDetails.id),
            section: JSON.parse(props.examDetails.section),
            practical: 0,
            theory: 0,
          })
        );

        if (response) {
          // Sync with `listOfMarks` after API response
          setListOfMarks((prevMarks) =>
            prevMarks.map((mark) =>
              mark.student === row.id
                ? { ...mark, [markType]: response[markType] }
                : mark
            )
          );
        }
      } catch (error) {
        console.error('Error updating marks:', error);
      }
    }, 500);
  };

  const filteredUsers = listOfUsers.filter((user) =>
    user.first_name.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div
      className="CreateResultManagementDetails"
      data-testid="CreateResultManagementDetails"
    >
      <Container sx={{}} maxWidth="sm">
        <Box
          sx={{
            backgroundColor: '#2e3b55',
            // borderRadius: '8px',
            border: '1px solid #ddd',
            // p: 2,
            mb: 2,
          }}
        >


          <Box
            sx={{
              backgroundColor: '#fff',
              // borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <Table
              sx={{
                p: 0,
                width: '100%',
                fontSize: '0.9rem',
                '& td': {
                  p: '4px 8px',
                  fontSize: '0.9rem',
                },
                '& .label': {
                  fontWeight: 'bold',
                  color: '#555',
                },
                '& .value': {
                  fontWeight: 'normal',
                  color: '#000',
                  textAlign: 'left',
                },
              }}
            >
              <TableBody>
                <TableRow sx={{ backgroundColor: '#fff' }}>
                  <TableCell className="label">Class:</TableCell>
                  <TableCell className="value">{props.examDetails.className}</TableCell>
                  <TableCell className="label">Section:</TableCell>
                  <TableCell className="value">{props.examDetails.section_name}</TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: '#fff' }}>
                  <TableCell className="label">Subject:</TableCell>
                  <TableCell className="value">{props.examDetails.subject}</TableCell>
                  <TableCell className="label">Max Marks:</TableCell>
                  <TableCell className="value">{props.examDetails.totalMarks}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>

        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#2e3b55' }}>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    p: 1,
                    textAlign: 'start',
                  }}
                >
                  Students
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    p: 1,
                    textAlign: 'center',
                    width: '80px', // Fixed width
                  }}
                >
                  Theory
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    p: 1,
                    textAlign: 'center',
                    width: '80px', // Fixed width
                  }}
                >
                  Practical
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                    '&:hover': {
                      backgroundColor: '#f1f1f1',
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      py: 1,
                      px: 1,
                      border: '1px solid #ddd',
                      verticalAlign: 'middle',
                      textAlign: 'start',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.first_name} {row.last_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.8rem',
                        color: '#555',
                      }}
                    >
                      {row.student_roll_no}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: 0,
                      border: '1px solid #ddd',
                      textAlign: 'center',
                      width: '80px', // Fixed width
                    }}
                  >
                    <TextField
                      variant="outlined"
                      value={
                        listOfMarks.some((mark) => mark.student === row.id)
                          ? listOfMarks.find((mark) => mark.student === row.id).theory
                          : localMarks[row.id]?.theory || ''
                      }
                      onChange={(e) => HandleUpdateMarks(e.target.value, 'theory', row)}
                      placeholder="0.0"
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          padding: '15px 0',
                          height: '100%',
                          width: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 0,
                          height: '100%',
                          '& fieldset': {
                            border: 'none !important',
                          },
                          '&:hover fieldset': {
                            border: 'none !important',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none !important',
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'center',
                          padding: 0,
                        },
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      padding: 0,
                      border: '1px solid #ddd',
                      textAlign: 'center',
                      width: '80px', // Fixed width
                    }}
                  >
                    <TextField
                      variant="outlined"
                      value={
                        listOfMarks.some((mark) => mark.student === row.id)
                          ? listOfMarks.find((mark) => mark.student === row.id).practical
                          : localMarks[row.id]?.practical || ''
                      }
                      onChange={(e) => HandleUpdateMarks(e.target.value, 'practical', row)}
                      placeholder="0.0"
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          padding: '15px 0',
                          height: '100%',
                          width: '100%',
                          boxSizing: 'border-box',
                        },
                      }}
                      sx={{
                        width: '100%',
                        height: '100%',
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 0,
                          height: '100%',
                          '& fieldset': {
                            border: 'none !important',
                          },
                          '&:hover fieldset': {
                            border: 'none !important',
                          },
                          '&.Mui-focused fieldset': {
                            border: 'none !important',
                          },
                        },
                        '& .MuiOutlinedInput-input': {
                          textAlign: 'center',
                          padding: 0,
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </Container>
    </div>
  );
};

CreateResultManagementDetails.propTypes = {
  examDetails: PropTypes.shape({
    class: PropTypes.string,
    section: PropTypes.string,
    subject: PropTypes.string,
    totalMarks: PropTypes.number,
  }).isRequired,
};

CreateResultManagementDetails.defaultProps = {};

export default CreateResultManagementDetails;
