import React from 'react';
import { Box, TextField } from '@mui/material';

const PasscodeInput = ({ passcode, setPasscode }) => {
  const handleChange = (value, index) => {
    const newPasscode = [...passcode];
    newPasscode[index] = value;
    setPasscode(newPasscode.join(''));

    // Automatically move to the next box
    if (value && index < 5) {
      document.getElementById(`passcode-box-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !passcode[index] && index > 0) {
      // Move to the previous box on backspace
      document.getElementById(`passcode-box-${index - 1}`).focus();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        marginTop: '16px',
      }}
    >
      {Array(6)
        .fill('')
        .map((_, index) => (
          <TextField
            key={index}
            id={`passcode-box-${index}`}
            variant="outlined"
            inputProps={{
              maxLength: 1,
              inputMode: 'numeric', // Mobile-friendly numeric keyboard
              pattern: '[0-9]*', // Ensure numeric input
              style: {
                textAlign: 'center',
                fontSize: '20px',
                padding: '8px',
                width: '40px',
              },
            }}
            value={passcode[index] || ''}
            onChange={(e) =>
              handleChange(e.target.value.replace(/[^0-9]/g, ''), index)
            }
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
    </Box>
  );
};

export default PasscodeInput;
