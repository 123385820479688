import React, { useState } from 'react';
import './Layout.scss';
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useAuth } from '../../services/authContext'; // Import AuthContext

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3',
    },
    dark: {
      main: '#111111',
    },
    background: {
      default: '#081727',
      paper: '#081727',
      warning: '#f5c44e'
    },
  },
});

const Layout = () => {
  const { loginStatus } = useAuth(); // Access login status from AuthContext

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          background: "#eeeeee",
          position: "fixed",
          width: "100%",
          overflowX: "hidden",
          height: "100vh",
        }}
      >
        <Header />
        <Box
          
        >
          <Outlet />
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

Layout.propTypes = {};

export default Layout;
