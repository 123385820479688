import { ArrowBack } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const TopBackBar = ({ title, to = "/" }) => {
return (
    <AppBar
        position="fixed"
        color="primary"
        sx={{ marginTop: { xs: "56px", sm: "60px" } }}

    >
        <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="back"
                sx={{ mr: 2 }}
                component={Link}
                to={to}
            >
                <ArrowBack sx={{ color: "white" }} />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {title}
            </Typography>
        </Toolbar>
    </AppBar>
);
};

export default TopBackBar;
