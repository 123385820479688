import React from 'react';
import PropTypes from 'prop-types';
import './Privacy.scss';
import { useAuth } from '../../services/authContext';

const Privacy = () => {
  const { loginStatus, setLoginStatus } = useAuth();



  return (
  <div className="Privacy" data-testid="Privacy">
    <h1>Data and Privacy Policy for Xtute Hello Client App</h1>

    <p>At Xtute Technologies Pvt Ltd ("Xtute," "we," "us," or "our"), your privacy is of utmost importance to us. This Data and Privacy Policy ("Policy") outlines how we collect, use, disclose, and protect your information when you use the Xtute Hello Client App (the "App"). By using the App, you consent to the practices described in this Policy.</p>

    <h2>1. Information We Collect</h2>
    <p>We collect the following types of information:</p>

    <h3>1.1 Personal Information</h3>
    <p>When you register for an account, we may collect personal information, including but not limited to:</p>
    <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Payment information</li>
    </ul>

    <h3>1.2 Non-Personal Information</h3>
    <p>We may collect non-personal information about your use of the App, including:</p>
    <ul>
        <li>Device information (e.g., device type, operating system)</li>
        <li>IP address</li>
        <li>Browser type</li>
        <li>Usage data (e.g., pages viewed, time spent)</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We may use your information for the following purposes:</p>
    <ul>
        <li>To provide and maintain the App.</li>
        <li>To process your transactions and manage your account.</li>
        <li>To communicate with you about your account and the services we provide.</li>
        <li>To improve our App and services.</li>
        <li>To send you promotional content and updates (you can opt-out at any time).</li>
    </ul>

    <h2>3. Sharing Your Information</h2>
    <p>We do not sell your personal information to third parties. We may share your information in the following circumstances:</p>
    <ul>
        <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating the App, processing payments, and providing customer support.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
    </ul>

    <h2>4. Data Security</h2>
    <p>We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

    <h2>5. Your Rights</h2>
    <p>You have the following rights regarding your personal information:</p>
    <ul>
        <li><strong>Access:</strong> You may request a copy of the personal information we hold about you.</li>
        <li><strong>Correction:</strong> You may request corrections to any inaccurate or incomplete information.</li>
        <li><strong>Deletion:</strong> You may request the deletion of your personal information, subject to certain legal exceptions.</li>
    </ul>
    <p>To exercise these rights, please contact us using the information provided below.</p>

    <h2>6. Changes to This Policy</h2>
    <p>We may update this Policy from time to time. We will notify you of any changes by posting the new Policy on the App. Your continued use of the App after the posting of changes constitutes your acceptance of those changes.</p>

    <h2>7. Contact Us</h2>
    <p>If you have any questions or concerns about this Data and Privacy Policy, please contact us at:</p>
    <p><strong>Email:</strong> <a href="mailto:info@xtute.com">info@xtute.com</a></p>
    <p><strong>Address:</strong> [Xtute Technologies Pvt Ltd, Shop No 334, Sector 7a, Faridabad, Haryana 121006]</p>
    <p><strong>Phone:</strong> [+917042462748]</p>

    <p>By using the Xtute Hello Client App, you acknowledge that you have read and agree to this Data and Privacy Policy.</p>
  </div>
  );
};

export default Privacy;
