import React from 'react'
import PropTypes from 'prop-types'
import './StudentHomework.scss'
import { useAuth } from '../../services/authContext'
import {
  Container,
  Box,
  Fab,
  TextField,
  Button,
  Modal,
  Typography,
  Divider,
  List,
  ListItem,
  IconButton,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Drawer
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {
  ListProductApi,
  CreateTaskApi,
  ListTaskApi,
  CreateCourseApi,
  UpdateCourseApi,
  DeleteCourseApi,
  UpdateCourseImageApi,
  DeleteSectionApi,
  ListTimeTableApi,
  ListClassRelatedToSectionApi,
  ListSubjectFromClassNameApi,
  UpdateTimeTableApi
} from '../../services/apis'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import ListItemText from '@mui/material/ListItemText'
import { BEBase } from '../../constants/app.constant'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import TimeTableDetails from '../TimeTableDetails/TimeTableDetails'
import StudentHomeWorkDetails from '../StudentHomeWorkDetails/StudentHomeWorkDetails'
import dayjs from 'dayjs'
import { ArrowBack } from '@mui/icons-material'
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';


const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};


const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH'
}

const UpdateStyle = {
  position: 'absolute',
  top: '22%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100vW',
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '100vH'
}

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}

const options = ['Update', 'Delete']

const ITEM_HEIGHT = 48

const StudentHomework = props => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [filter, setFilter] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState('')
  const [heading, setHeading] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [amount, setAmount] = React.useState('')
  const [paymentLink, setPaymentLink] = React.useState('')
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [productName, setProductName] = React.useState('')
  const [productDescription, setProductDescriptionn] = React.useState('')
  const [productCategory, setProductCategory] = React.useState('')
  const [productPrice, setProductPrice] = React.useState('')
  const [discountedPrice, setDiscountedPrice] = React.useState('')
  const [productPic1, setProductPic1] = React.useState()
  const [productPic2, setProductPic2] = React.useState()
  const [productPic3, setProductPic3] = React.useState()
  const [productPic4, setProductPic4] = React.useState()
  const [productPic5, setProductPic5] = React.useState()
  const [imgOpen, setImgOpen] = React.useState(false)
  const [selectedID, setSelectedID] = React.useState('')
  const [openedImage, setOpenedImage] = React.useState('')
  const [syllabus, setSyllabus] = React.useState()
  const [changeImgFlag, setChangeImgFlag] = React.useState('')
  const [imageCacheKey, setImageCacheKey] = React.useState(Date.now())
  // State to manage dynamically added textboxes
  const [textboxes, setTextboxes] = React.useState([])
  const [textboxId, setTextBoxId] = React.useState([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const openme = Boolean(anchorEl)
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null)
  const openmeAccordion = Boolean(anchorElAccordion)
  const [selectedDropDown, setSelectedDropDown] = React.useState('')
  const [deleteSectionOpen, setDeleteSectionOpen] = React.useState(false)
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [openMenuId, setOpenMenuId] = React.useState(null)
  const [classList, setClassList] = React.useState([])
  const [className, setClassName] = React.useState('')
  const [timetableData, setTimetableData] = React.useState([])
  const [value, setValue] = React.useState('')
  const [addedClassFromSection, setAddedClassFromSection] = React.useState([])

  const handleClick = async (event, selectID) => {
    setAnchorEl(event.currentTarget)
    setSelectedDropDown(selectID)
  }

  const handleAccordianClick = (event, selectID) => {
    event.stopPropagation()
    setAnchorElAccordion(event.currentTarget) // Set the anchor for the accordion menu
    setOpenMenuId(selectID) // Set the id for the menu being opened
  }

  const handleCloseMe = () => {
    setAnchorEl(null)
  }

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null)
    setOpenMenuId(null)
  }

  // Function to add a new textbox (up to a limit of 10)
  const handleAddTextbox = () => {
    if (textboxes.length < 10) {
      setTextboxes([...textboxes, ''])
    }
  }

  // Function to handle changes in dynamically added textboxes
  const handleTextboxChange = (index, value) => {
    const updatedTextboxes = [...textboxes]
    updatedTextboxes[index] = value
    setTextboxes(updatedTextboxes)
  }

  const handleRemoveTextbox = index => {
    const updatedTextboxes = textboxes.filter((_, i) => i !== index)
    setTextboxes(updatedTextboxes)
    const updatedTextboxesId = textboxId.filter((_, i) => i !== index)
    setTextBoxId(updatedTextboxesId)
  }

  const handleAction = async action => {
    if (action.toLowerCase() === 'delete') {
      setDeleteSectionOpen(true)
    }
  }

  const handleAccordianAction = async (action, item) => {
    if (action.toLowerCase() === 'delete') {
      setDeleteProductOpen(true)
      setSelectedID(item.id)
    } else if (action.toLowerCase() === 'update') {
      const textBoxArr = []
      const textboxIdArr = []
      setSelectedID(item.id)
      setProductName(item.productName)
      setProductDescriptionn(item.productDescription)
      item.sections.forEach((element, index) => {
        textBoxArr[index] = element.section_text
        textboxIdArr[index] = element.id
      })
      setTextboxes(textBoxArr)
      setTextBoxId(textboxIdArr)
      handleOpen()
      //setDeleteProductOpen(true);
    }
  }

  const handleSectionDelete = async action => {
    try {
      const formData = new FormData()
      formData.append('id', selectedDropDown.id)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteSectionApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  React.useEffect(() => {

    if (listApiFlag && loginStatus.user && (loginStatus.user.staff_type === "teacher" || loginStatus.user.is_superuser)) {
      const fetchListProducts = async () => {
        try {
          const response = await ListProductApi()
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
    }
  }, [loginStatus])

  const handleUpdateTask = async () => {
    try {
      const textBoxJson = []
      textboxes.forEach((element, index) => {
        textBoxJson[index] = { id: textboxId[index], section_text: element }
      })
      const formData = JSON.stringify({
        id: selectedID,
        productName: productName,
        productDescription: productDescription,
        section_text: textBoxJson
      })

      const response = await UpdateCourseApi(formData)
      console.log('Product created successfully:', response)
      handleClose()
      window.location.reload()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const filteredUsers = listOfUsers.filter(user =>
    user.productName.toLowerCase().includes(filter.toLowerCase())
  )

  const [open, setOpen] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleOpenCreate = () => {
    setOpenCreate(true)
  }

  const handleImageOpen = path => {
    setOpenedImage(path)
    setImgOpen(true)
    setImageCacheKey(Date.now())
  }
  const handleClose = () => {
    setOpen(false)
    setOpenCreate(false)
  }

  const handleCreateTask = async () => {
    try {
      const response = await CreateTaskApi({
        username: selectedUser,
        taskHeading: heading,
        taskDescription: description,
        amount: amount,
        paymentLink: paymentLink
      })
      console.log('Task created successfully:', response)
      // Optionally reset form fields here
      handleClose()
    } catch (error) {
      console.error('Error creating task:', error)
    }
  }

  const handleCreateProduct = async () => {
    try {
      const formData = JSON.stringify({
        productName: productName,
        productDescription: productDescription,
        section_text: textboxes
      })

      const response = await CreateCourseApi(formData)
      console.log('Product created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const handleUpdateImageTask = async imagePath => {
    const formData = new FormData()
    formData.append('changeImgFlag', changeImgFlag)
    formData.append('imagePath', imagePath)
    var response = await UpdateCourseImageApi(formData)
  }

  const handleDeleteProduct = async deleteId => {
    try {
      const formData = new FormData()
      formData.append('id', selectedID)

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteCourseApi(formData)

      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  const showTimeTable = async (e, sectionDetails) => {
    e.stopPropagation()
    try {
      try {
        const encryptedCreds = localStorage.getItem('loginCred')

        if (!encryptedCreds) {
          throw new Error('No login credentials found in localStorage')
        }

        const decryptedCreds = decryptData(encryptedCreds)

        if (!decryptedCreds || !decryptedCreds.token) {
          throw new Error(
            'Failed to retrieve valid token from decrypted credentials'
          )
        }

        const authToken = JSON.stringify(decryptedCreds)
        const payload = {
          className: sectionDetails.section_text,
          id: JSON.parse(authToken).user.id
        }
        const response = await ListTimeTableApi(payload)

        // Function to remove escape characters and parse the JSON string
        const parseJsonString = jsonString => {
          // Remove the escape characters and parse the JSON string
          return JSON.parse(jsonString.replace(/\\"/g, '"'))
        }

        // Assuming you need to parse the JSON strings for each day of the week
        response.forEach(element => {
          // For each element in the response, parse the timetable data for each day
          element.monday = parseJsonString(element.monday)
          element.tuesday = parseJsonString(element.tuesday)
          element.wednesday = parseJsonString(element.wednesday)
          element.thursday = parseJsonString(element.thursday)
          element.friday = parseJsonString(element.friday)
          element.saturday = parseJsonString(element.saturday)
          element.sunday = parseJsonString(element.sunday)
          element.className = element.className
          element.section_id = element.section_id
        })
        // Now the parsedResponse contains the parsed JSON objects
        const fresponse = response.filter(
          x => x.section_id === sectionDetails.id
        )
        // Set the parsed response into state or use it as needed
        setTimetableData(fresponse)

        // Perform any additional actions, like opening a modal
        handleOpen()
      } catch (error) {
        // Log the error if something goes wrong
        console.error('Error fetching timetable data:', error)
      }
    } catch (error) { }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 },
          mb: { xs: 5, md: 7 },
          paddingBottom: { xs: '80px', md: '80px' },
          // bgcolor: 'background.paper'
        }}>
        {loginStatus.token && (
          <Box>
            {loginStatus.user.staff_type === "teacher" || loginStatus.user.is_superuser ? (
              <div className='table-cover'>
                {/* <h1>Click on Any Section to view Set and View Home Work</h1> */}
                <TextField
                  fullWidth
                  label='Filter Class'
                  id='fullWidth'
                  value={filter}
                  sx={{ marginBottom: 3, mt: 5 }}
                  onChange={e => setFilter(e.target.value)}
                />
                {filteredUsers.map(item => (
                  <Accordion defaultExpanded key={item.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                    >
                      <div>
                        {item.productName}
                        <br />
                        <small style={{ color: '#666' }}>
                          {item.productDescription}
                        </small>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.sections.map(getSection => (
                        <div
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '6px 12px', // Add padding for spacing inside
                            fontSize: '16px', // Font size for the label
                            borderRadius: '16px', // Rounded corners for a smooth appearance
                            border: '2px solid #e4b648', // Border color matching primary color
                            color: '#e4b648', // Text color matches the border
                            backgroundColor: 'transparent', // Transparent background
                            fontWeight: 600, // Slightly bolder text
                            cursor: 'pointer', // Pointer cursor for interactivity
                            transition: 'background-color 0.3s, color 0.3s', // Smooth transition on hover
                            marginLeft: '15px'
                          }}
                          onClick={e => showTimeTable(e, getSection)} // Prevent clicks on the label from triggering delete
                        >
                          {/* Label */}
                          <span>{getSection.section_text}</span>

                          {/* Delete Icon */}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ))}


                <Drawer
                  anchor='right'
                  open={open}
                  onClose={handleClose}
                  sx={{
                    '& .MuiDrawer-paper': {
                      width: '100%' // Makes the drawer full screen
                    }
                  }}
                >
                  {/* <Box sx={{ ...UpdateStyle, position: 'relative' }}> */}
                  <>
                    <Box>
                      {/* Modal Header (Fixed) */}
                      <Box
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          pt: '15px',
                          color: '#fff',
                          position: 'sticky',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0,
                          zIndex: 1000,
                          width: '100%',
                          pb: '15px'
                        }}
                      >
                        <ArrowBack
                          onClick={handleClose}
                          style={{
                            cursor: 'pointer',
                            color: '#ffffff',
                            position: 'absolute',
                            left: 20
                          }}
                        />
                        <Typography variant='h6'>
                          Home Work
                        </Typography>
                      </Box>
                      <Container marginTop="4px">

                        <StudentHomeWorkDetails
                          homeWorkData={timetableData}
                        />
                      </Container>
                      {/* Modal Content */}
                    </Box>
                  </>
                  {/* </Box> */}
                </Drawer>
                {false && <>

                  <Modal
                    fullscreen
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Box sx={{ ...UpdateStyle, position: 'relative' }}>
                      <>
                        <Box>
                          {/* Modal Header (Fixed) */}
                          <Box
                            sx={{
                              backgroundColor: theme.palette.primary.main,
                              padding: '10px',
                              color: '#fff',
                              position: 'sticky',
                              top: 0,
                              zIndex: 1000,
                              width: '100%'
                            }}
                          >
                            <Typography variant='h6'>Home Work </Typography>
                            <CloseIcon
                              onClick={handleClose}
                              style={{
                                position: 'absolute',
                                top: 16,
                                right: 16,
                                cursor: 'pointer',
                                color: '#ffffff'
                              }}
                            />
                          </Box>
                          <StudentHomeWorkDetails
                            homeWorkData={timetableData}
                          />
                          {/* Modal Content *
                        <TableContainer component={Paper} sx={{ marginTop: '20px', marginBottom: '20px' }}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell sx={{ color: theme.palette.primary.main }}>Start Time</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }}>End Time</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Monday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Tuesday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Wednesday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Thursday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Friday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Saturday</TableCell>
                                <TableCell sx={{ color: theme.palette.primary.main }} align="center">Sunday</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/* Iterate over timetableData (each day's schedule) 
                              {timetableData.map((row, rowIndex) => ( 
                                  ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8'].map((period) => (
                                    <TableRow key={rowIndex + period}>
                                      <TableCell
                                        align="center"
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        {/* Access the subject for each day and period 
                                        {row && row[period] && row[period].startTime !== '' ? 
                                          row[period].startTime : 'No Start Time'}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{ cursor: 'pointer' }}
                                      >
                                        {/* Access the subject for each day and period 
                                        {row && row[period] && row[period].endTime !== '' ? 
                                          row[period].endTime : 'No End Time'}
                                      </TableCell>
                                      {/* Iterate over days of the week *
                                      {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                                        <>
                                          <TableCell
                                            key={day}
                                            align="center"
                                            sx={{ cursor: 'pointer' }}
                                          >
                                            {/* Access the subject for each day and period 
                                            <Autocomplete
                                              id="free-solo-demo-class"
                                              options={addedClassFromSection.subject.map((option) => option.subjectName)}
                                              value={row[day] && row[day][period] && row[day][period].subject !== '' ? 
                                                row[day][period].subject : ''}
                                              required
                                              onChange={(e) => setProductName(e.target.value)}
                                              renderInput={(params) => <TextField fullWidth {...params} label="Select Class" required />}
                                              sx={{ marginBottom: 3 }}
                                            />
                                          </TableCell>
                                        </>
                                      ))}
                                    </TableRow>
                                  ))
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer> */}
                        </Box>
                      </>
                    </Box>
                  </Modal>
                  <Modal
                    fullscreen
                    open={imgOpen}
                    onClose={() => setImgOpen(false)}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Box sx={{ ...ImgStyle, position: 'relative' }}>
                      <CloseIcon
                        onClick={() => setImgOpen(false)}
                        style={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          cursor: 'pointer'
                        }}
                      />
                      <Box>
                        <img
                          src={`${BEBase}media/${openedImage}?cache=${imageCacheKey}`}
                          style={{ width: '100%' }}
                        />
                      </Box>
                      <TextField
                        fullWidth
                        required
                        className='register-form'
                        type='file'
                        sx={{ marginBottom: 3 }}
                        onChange={e => {
                          const file = e.target.files[0]
                          if (file) {
                            setChangeImgFlag(file)
                          }
                        }}
                      />
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={() => {
                          handleUpdateImageTask(openedImage)
                          setImgOpen(false) // Close the modal after the update
                        }}
                      >
                        Change Image
                      </Button>
                    </Box>
                  </Modal>
                  <Modal
                    fullscreen
                    open={deleteProductOpen}
                    onClose={() => setDeleteProductOpen(false)}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <Box sx={{ ...ImgStyle, position: 'relative' }}>
                      <CloseIcon
                        onClick={() => setDeleteProductOpen(false)}
                        style={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          cursor: 'pointer'
                        }}
                      />
                      <h3>Are you Sure You want to delete this Class</h3>
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={() => {
                          handleDeleteProduct()
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={() => setDeleteProductOpen(false)}
                      >
                        No
                      </Button>
                    </Box>
                  </Modal>
                  <Modal
                    fullscreen
                    open={openCreate}
                    onClose={handleClose}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    sx={{ overflow: 'auto' }}
                  >
                    <Box sx={{ ...style, position: 'relative' }}>
                      <CloseIcon
                        onClick={handleClose}
                        style={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          cursor: 'pointer'
                        }}
                      />
                      <Typography
                        id='modal-modal-title'
                        variant='h6'
                        component='h2'
                        sx={{ marginBottom: 3 }}
                      >
                        Create New Class
                      </Typography>
                      <TextField
                        fullWidth
                        required
                        label='Class Name'
                        className='register-form'
                        value={productName}
                        onChange={e => setProductName(e.target.value)}
                        sx={{ marginBottom: 3 }}
                      />
                      <TextField
                        fullWidth
                        required
                        label='Class Description'
                        className='register-form'
                        value={productDescription}
                        onChange={e => setProductDescriptionn(e.target.value)}
                        sx={{ marginBottom: 3 }}
                      />

                      {/* Dynamically added textboxes */}
                      {textboxes.map((textbox, index) => (
                        <>
                          <TextField
                            key={index}
                            fullWidth
                            label={`Add Section ${index + 1}`}
                            value={textbox}
                            onChange={e =>
                              handleTextboxChange(index, e.target.value)
                            }
                            sx={{ marginBottom: 3 }}
                          />

                          <IconButton
                            onClick={() => handleRemoveTextbox(index)}
                            sx={{
                              position: 'absolute',
                              right: '30px',
                              marginTop: '8px',
                              color: 'red',
                              cursor: 'pointer'
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </>
                      ))}
                      {/* Button to add a new textbox */}
                      <Button
                        variant='outlined'
                        onClick={handleAddTextbox}
                        disabled={textboxes.length >= 10} // Disable button if 10 textboxes already exist
                      >
                        Add Section
                      </Button>
                      <Button
                        variant='contained'
                        className='btn'
                        onClick={handleCreateProduct}
                      >
                        Create Class
                      </Button>
                    </Box>
                  </Modal>
                </>}
              </div>
            ) : (
              <div className='task-cover'>
                {filteredUsers.map(item => (
                  <>
                    <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                    <List sx={{ width: '100%', bgcolor: 'secondary' }}>
                      <ListItem alignItems='flex-start'>
                        <ListItemText
                          primary={item.taskHeading}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component='span'
                                variant='body2'
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {item.taskDescription}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      {item.amount > 0 ? (
                        <Button component='a' href={item.paymentLink}>
                          Pay Rs. {item.amount}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </List>
                    <Divider variant='inset' sx={{ marginLeft: '0px' }} />
                  </>
                ))}
              </div>
            )}
          </Box>
        )}
      </Container>

    </ThemeProvider>
  )
}

StudentHomework.propTypes = {}

StudentHomework.defaultProps = {}

export default StudentHomework
