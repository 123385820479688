import React from "react";
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Avatar,
  TextField,
  Button,
  Autocomplete,
  Container,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BEBaseRoot } from "../../constants/app.constant";

const StudentForm = ({
  open,
  onClose,
  onSubmit,
  mode = "create", // 'create' or 'update'
  studentData = {},
  setStudentData,
}) => {
  const handleInputChange = (field, value) => {
    setStudentData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setStudentData((prev) => ({
          ...prev,
          profile_picture: reader.result,
          profile_picture_file: file,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%", // Makes the drawer full screen
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        role="presentation"
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #ccc",
            pb: 2,
            mb: 2,
            backgroundColor: "#0d2238",
            color: "#ffffff",
            padding: "16px",
          }}
        >
          <IconButton onClick={onClose}>
            <ArrowBackIcon sx={{ color: "#ffffff" }} />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            {mode === "create" ? "Create New Student" : "Update Student"}
          </Typography>
          <Box sx={{ width: 48 }} /> {/* Spacer to balance header layout */}
        </Box>

        {/* Content */}
        <Container sx={{ padding: 3 }}>
          {/* Profile Picture */}
          <Box sx={{ textAlign: "center", marginBottom: 3 }}>
            <Avatar
              src={
                studentData.profile_picture?.startsWith("data:image")
                  ? studentData.profile_picture
                  : studentData.profile_picture
                  ? BEBaseRoot + studentData.profile_picture
                  : "https://via.placeholder.com/120"
              }
              alt="Profile Picture"
              sx={{ width: 120, height: 120, margin: "0 auto" }}
            />
            <IconButton
              component="label"
              sx={{
                position: "relative",
                bottom: 20,
                backgroundColor: "#e4b648",
                color: "#fff",
                "&:hover": { backgroundColor: "#d4a238" },
              }}
            >
              <CameraAltIcon />
              <input type="file" hidden accept="image/*" onChange={handleFileChange} />
            </IconButton>
          </Box>

          {/* Form Fields */}
          <TextField
            fullWidth
            required
            label="First Name"
            value={studentData.first_name || ""}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <TextField
            fullWidth
            required
            label="Last Name"
            value={studentData.last_name || ""}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <TextField
            fullWidth
            required
            type="email"
            label="Email"
            value={studentData.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <TextField
            fullWidth
            required
            label="Phone"
            value={studentData.phone || ""}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <TextField
            fullWidth
            label="Address"
            value={studentData.address || ""}
            onChange={(e) => handleInputChange("address", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <TextField
            fullWidth
            required
            label="Date of Birth"
            type="date"
            value={studentData.dob || ""}
            onChange={(e) => handleInputChange("dob", e.target.value)}
            sx={{ marginBottom: 3 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            label="Roll Number"
            value={studentData.student_roll_no || ""}
            onChange={(e) => handleInputChange("student_roll_no", e.target.value)}
            sx={{ marginBottom: 3 }}
          />
          <Autocomplete
            options={["Male", "Female", "Other"]}
            value={studentData.gender || ""}
            onChange={(event, newValue) => handleInputChange("gender", newValue)}
            renderInput={(params) => <TextField {...params} label="Select Gender" fullWidth />}
            sx={{ marginBottom: 3 }}
          />

          <Button
            variant="contained"
            onClick={() => onSubmit(studentData)}
            disabled={
              !studentData.first_name ||
              !studentData.last_name ||
              !studentData.phone ||
              !studentData.email
            }
          >
            {mode === "create" ? "Create Student" : "Update Student"}
          </Button>
        </Container>
      </Box>
    </Drawer>
  );
};

export default StudentForm;
