import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Select,
  MenuItem,
  Card,
  CardContent,
  Box,
  Grid
} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import {
  ListTeacherTimetableApi,
  ListSectionFromIdApi,
  ListClassFromIdApi,
} from '../../services/apis'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF9800' // Orange color
    }
  }
})

const TeacherTimeTable = () => {
  const [day, setDay] = useState('monday')
  const [timetable, setTimetable] = useState([])
  const [sectionNames, setSectionNames] = useState({})
  const { loginStatus, setLoginStatus } = useAuth()
  const [listApiFlag, setListApiFlag] = useState(true)

  const handleChange = event => {
    setDay(event.target.value)
  }

  useEffect(() => {
    if (listApiFlag && loginStatus?.user) {
      const fetchTimetable = async () => {
        const teacherId = loginStatus.user.id
        try {
          const response = await ListTeacherTimetableApi({ teacherId })
          if (response?.classes) {
            setTimetable(response.classes)

            const sectionPromises = response.classes.map(async entry => {
              if (entry.sectionId) {
                const sectionResponse = await ListSectionFromIdApi({
                  id: entry.sectionId
                })
                const classResponse = await ListClassFromIdApi({
                  id: sectionResponse[0]?.product_id
                })

                return {
                  sectionId: sectionResponse[0]?.id,
                  sectionName: sectionResponse[0]?.section_text || 'Unknown',
                  className: classResponse[0]?.productName || 'Unknown Class'
                }
              }
              return null
            })

            const sectionData = await Promise.all(sectionPromises)
            const sectionMapping = sectionData.reduce((acc, curr) => {
              if (curr) {
                acc[curr.sectionId] = {
                  name: curr.sectionName,
                  className: curr.className
                }
              }
              return acc
            }, {})
            setSectionNames(sectionMapping)
          }
        } catch (error) {
          console.error('Error fetching timetable:', error)
        } finally {
          setListApiFlag(false)
        }
      }

      fetchTimetable()
    }
  }, [loginStatus, listApiFlag])

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, height: '100vh', flexDirection: 'column', marginTop: '80px' }}>

        <Container sx={{ mt: 3 }}>
          <Select value={day} onChange={handleChange} fullWidth>
            {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(dayName => (
              <MenuItem key={dayName} value={dayName}>
                {dayName.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {timetable.length > 0 ? (
              timetable.map((entry, index) => {
                const dayData = entry.week[day]
                const className = sectionNames[entry.sectionId]?.className || `Class (${entry.sectionId})`
                const sectionName = sectionNames[entry.sectionId]?.name || `Section (${entry.sectionId})`

                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card variant="outlined" sx={{ borderColor: 'primary.main', p: 2 }}>
                      <CardContent>
                        <Typography variant="h6">Class: {className} ({sectionName})</Typography>
                        {dayData?.length > 0 ? (
                          dayData.map((period, periodIndex) => (
                            <Box key={periodIndex}>
                              <Typography variant="subtitle1">{period.subjectName || 'No Subject'}</Typography>
                              <Typography variant="body2">{period.startTime} - {period.endTime}</Typography>
                            </Box>
                          ))
                        ) : (
                          <Typography>No classes for today.</Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                )
              })
            ) : (
              <Typography style={{textAlign:"center",marginInline:"auto"}}>No timetable data available</Typography>
            )}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  )
}

export default TeacherTimeTable
