import React from 'react'
import PropTypes from 'prop-types'
import './ComplaintManagemnent.scss'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent,
  Popover,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Modal,
  Fade,
  Paper,
  useMediaQuery,
  listClasses,
  TextField,
  Menu
} from '@mui/material'
import {
  CreateExamApi,
  ListExamApi,
  UpdateExamApi,
  DeleteExamApi
} from '../../services/apis'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'

import ArrowBack from '@mui/icons-material/ArrowBack'

import MoreVertIcon from '@mui/icons-material/MoreVert'

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%', // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'scroll',
  marginTop: '190px',
  maxHeight: '80vH',
  '@media (max-width: 600px)': {
    // Adjust the breakpoint as needed
    width: '100%' // Width for mobile devices
  }
}

const ComplaintManagemnent = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const [listOfUsers, setListOfUsers] = React.useState([])
  const [listApiFlag, setListApiFlag] = React.useState(true)
  const [ListProductApi, setListProductApi] = React.useState([])
  const [reason, setReason] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const options = ['Update', 'Delete']
  const [openMenuId, setOpenMenuId] = React.useState(null)
  const [anchorElAccordion, setAnchorElAccordion] = React.useState(null)
  const ITEM_HEIGHT = 48
  const [selectedID, setSelectedID] = React.useState('')
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false)
  const [updateOpen, setUpdateOpen] = React.useState(false)

  const theme = createTheme({
    palette: {
      primary: {
        main: '#284d84' // blue color
      },
      secondary: {
        main: '#EC4899' // Pink color for the check icons
      }
    },
    typography: {
      fontFamily: 'Arial, sans-serif'
    }
  })

  const handleOpen = () => {
    setUpdateOpen(true)
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleFabClick = event => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleUpdateClose = e => {
    e.preventDefault()
    setUpdateOpen(false) // Close the modal
  }

  const handleClose = e => {
    e.preventDefault()
    setOpen(false) // Close the modal
  }

  const handleAccordianClick = (event, selectID) => {
    event.stopPropagation()

    // Debugging: Log current state
    console.log('Clicked item ID:', selectID)
    console.log('Currently open menu ID:', openMenuId)

    if (openMenuId === selectID) {
      // Close the menu if the same item is clicked
      setAnchorElAccordion(null)
      setOpenMenuId(null)
    } else {
      // Open the menu for the new item
      setAnchorElAccordion(event.currentTarget)
      setOpenMenuId(selectID)
    }
  }

  const handleDeleteProduct = async () => {
    try {
      // Assuming DeleteCourseApi expects formData
      const response = await DeleteExamApi({ id: selectedID })
      console.log('Product deleted successfully:', response)
      handleClose()
    } catch (error) {
      console.error('Error deleting Product:', error)
    }
  }

  const handleUpdateTask = async () => {
    try {
      const response = await UpdateExamApi({ id: selectedID, exam: reason })
      console.log('Exam Updated successfully:', response)
      handleClose()
      window.location.reload()
    } catch (error) {
      console.error('Error creating Product:', error)
    }
  }

  const handleSubmit = async event => {
    event.preventDefault()

    try {
      const request_data = {
        exam: reason,
        id: loginStatus.user.id,
        status: 'unpublished'
      }

      const response = await CreateExamApi(request_data)

      console.log('Notice created successfully:', response)
      window.location.reload()
      handleClose()
    } catch (error) {
      console.error('Error creating notice:', error)
    }
  }

  const handleCloseMeAccordion = () => {
    setAnchorElAccordion(null)
    setOpenMenuId(null)
  }

  const handleAccordianAction = async (action, item) => {
    console.log('getitemmm', item)
    if (action.toLowerCase() === 'delete') {
      setDeleteProductOpen(true)
      setSelectedID(item.id)
    } else if (action.toLowerCase() === 'update') {
      const textBoxArr = []
      const textboxIdArr = []
      console.log('myitem', item)
      setSelectedID(item.id)
      setReason(item.exam)
      handleOpen()
      //setDeleteProductOpen(true);
    }
  }


  React.useEffect(() => {
    if (listApiFlag && loginStatus.user.is_superuser || loginStatus.user.staff_type === "teacher" ) {
      const fetchListProducts = async () => {
        try {
          const response = await ListExamApi({ id: loginStatus.user.id })
          setListOfUsers(response)
          setListApiFlag(false)
        } catch (error) {
          console.error('Error fetching users:', error)
          setListApiFlag(false)
        }
      }

      fetchListProducts()
    }
  }, [listApiFlag, loginStatus])

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          flexGrow: 1,
          mt: { xs: 2, md: 5 },
          mb: { xs: 5, md: 7 },
          paddingBottom: { xs: '80px', md: '80px' },
          // bgcolor: 'background.paper'
        }}>
        {loginStatus.token && (
          <Box
            sx={{
              p: 2,
              mt: 2,
              marginLeft: isMobile ? '0px' : '510px',
              marginTop: isMobile ? '30px' : '70px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              sm: 'center',
              lg: 'center',
              xl: 'center',
              padding: '-20px' // Adjust padding or any other styles for iPad
              // backgroundColor: 'lightblue', // Example background color
            }}
          >
            <Modal
              open={open}
              onClose={() => setOpen(false)} // Close modal when clicking outside
              aria-labelledby='add-complaint-reason'
              aria-describedby='add-complaint-reason-description'
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 24
                }}
              >
                <Typography
                  id='add-complaint-reason'
                  variant='h6'
                  component='h1'
                  sx={{ marginBottom: -3, fontWeight: 'bold' }}
                >
                  Create Exam
                </Typography>
                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    marginLeft: isMobile ? '280px' : '420px',
                    marginTop: isMobile ? '-50px' : '-20px'
                  }}
                />
                <form
                  component='form'
                  onSubmit={handleSubmit}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextField
                    label='Enter exam name'
                    required
                    fullWidth
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                    variant='outlined'
                    margin='normal'
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='warning'
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Modal>
            <Modal
              fullscreen
              open={deleteProductOpen}
              onClose={() => setDeleteProductOpen(false)}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={{ ...ImgStyle, position: 'relative' }}>
                <CloseIcon
                  onClick={() => setDeleteProductOpen(false)}
                  style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    cursor: 'pointer'
                  }}
                />
                <h3>Are you Sure You want to delete this Examination</h3>
                <Button
                  variant='contained'
                  className='btn'
                  onClick={() => {
                    handleDeleteProduct()
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant='contained'
                  className='btn'
                  onClick={() => setDeleteProductOpen(false)}
                >
                  No
                </Button>
              </Box>
            </Modal>
            <Modal
              open={updateOpen}
              onClose={() => setUpdateOpen(false)} // Close modal when clicking outside
              aria-labelledby='add-complaint-reason'
              aria-describedby='add-complaint-reason-description'
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#fff',
                  padding: 3,
                  borderRadius: 2,
                  boxShadow: 24
                }}
              >
                <Typography
                  id='add-complaint-reason'
                  variant='h6'
                  component='h1'
                  sx={{ marginBottom: -3, fontWeight: 'bold' }}
                >
                  Update Exam
                </Typography>
                <CloseIcon
                  onClick={handleUpdateClose}
                  sx={{
                    marginLeft: isMobile ? '280px' : '420px',
                    marginTop: isMobile ? '-50px' : '-20px'
                  }}
                />
                <form
                  component='form'
                  onSubmit={handleUpdateTask}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <TextField
                    label='Enter exam name'
                    required
                    fullWidth
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                    variant='outlined'
                    margin='normal'
                  />
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='warning'
                    sx={{ marginTop: 2 }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Modal>
          </Box>
        )}



        <Grid
          container
          spacing={2}
          sx={{
            marginBottom: '200px',
          }}
        >
          {listOfUsers.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card sx={{ width: '100%' }}>
                <CardContent
                  sx={{
                    width: '100%',
                    padding: '15px !important',
                    position: 'relative'
                  }}
                >
                  <IconButton
                    aria-label='more'
                    id='long-button'
                    aria-controls={
                      openMenuId === item.id ? 'long-menu' : undefined
                    }
                    aria-expanded={openMenuId === item.id ? 'true' : undefined}
                    aria-haspopup='true'
                    sx={{
                      position: 'absolute',
                      right: 40,
                      top: '7px'
                    }}
                    onClick={e => handleAccordianClick(e, item.id)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id='long-menu'
                    MenuListProps={{
                      'aria-labelledby': 'long-button'
                    }}
                    anchorEl={openMenuId === item.id ? anchorElAccordion : null} // Tie to the correct button
                    open={openMenuId === item.id} // Open only for the selected item
                    onClose={handleCloseMeAccordion} // Close the menu
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        boxShadow: 'none',
                        border: '1px solid #666666'
                      }
                    }}
                  >
                    {options.map(option => (
                      <MenuItem
                        key={option}
                        onClick={e => {
                          e.stopPropagation() // Prevent event propagation
                          handleAccordianAction(option, item) // Handle action for the correct item
                          handleCloseMeAccordion() // Close the menu
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>

                  <Typography variant='body1'>{item.exam}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Fab
        color='primary'
        sx={{
          marginBottom: '55px',
          marginRight: '20px',
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000
        }}
        onClick={handleFabClick}
      >
        {/* Add your icon here, for example: */}
        <AddIcon />
      </Fab>
    </ThemeProvider>
  )
}

ComplaintManagemnent.propTypes = {}

ComplaintManagemnent.defaultProps = {}

export default ComplaintManagemnent
