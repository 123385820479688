import React from 'react';
import PropTypes from 'prop-types';
import './ExaminationManagement.scss';
import { useAuth } from '../../services/authContext';
import { ThemeProvider, createTheme, } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Fab,
  Select,
  MenuItem,
  Card,
  CardContent,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  SelectChangeEvent, useMediaQuery,
  Grid,
} from '@mui/material';
import ImageFilter from 'react-image-filter';
import {
  ListProductApi
} from '../../services/apis'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentIcon from '@mui/icons-material/Assignment'
import {
  CreateExamApi,
} from '../../services/apis'
import TopBackBar from '../Utility/TopBackBar';
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../componentConst';


const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23; // Replace with a secure key

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString); // Parse the JSON string
  } catch (error) {
    console.error('Decryption error:', error);
    return null; // Return null if decryption fails
  }
};


const theme = createTheme({
  palette: {
    primary: {
      main: '#284d84' // blue
    },
  },
});

const ExaminationManagement = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [day, setDay] = React.useState('thursday');
  const [value, setValue] = React.useState(2);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, setLoginStatus } = useAuth();
  const [listApiFlag, setListApiFlag] = React.useState(true);


  const cardConfig = [
    {
      link: '/createxaminationmanagement',
      image: '/img_frontend/paper.png',
      alt: 'Examination Icon',
      colorOne: [130, 73, 219],
      colorTwo: [130, 73, 219],
      text: 'Examination',
      count: null, // Replace with dynamic count if needed
    },
    {
      link: '/createresultmanagement',
      image: '/img_frontend/paper.png',
      alt: 'Create Result Icon',
      colorOne: [130, 73, 219], // Color for icon filter (if applicable)
      colorTwo: [130, 73, 219],
      text: 'Create Result',
      count: null, // Replace with dynamic count if needed
    },
    // {
    //   link: '/publishresultManagement',
    //   image: '/img_frontend/paper.png',
    //   alt: 'Publish Result Icon',
    //   colorOne: [130, 73, 219], // Color for icon filter (if applicable)
    //   colorTwo: [130, 73, 219],
    //   text: 'Publish Result',
    //   count: null, // Replace with dynamic count if needed
    // },
    // {
    //   link: '/examination',
    //   image: '/img_frontend/paper.png',
    //   alt: 'Result Icon',
    //   colorOne: [130, 73, 219], // Color for icon filter (if applicable)
    //   colorTwo: [130, 73, 219],
    //   text: 'Result',
    //   count: null, // Replace with dynamic count if needed
    // },
  ];


  const handleCardClick = (route) => {
    navigate(route); // Navigate to the respective route
  };

  const handleChange = (event) => {
    setDay(event.target.value);
  };


  return (
    <ThemeProvider theme={theme}>

      {loginStatus.token && (
        <Container sx={{
          p: 2,
          mt: 2,
          marginTop: "30px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>

          <Grid container spacing={2} sx={{ marginTop: '16px' }} justifyContent="start" alignItems="center">
            {cardConfig.map((card, cardIndex) => (
              <Grid item xs={6} sm={4} md={4} lg={3} key={cardIndex}>
                <Link to={card.link} className="linkTag" style={{ textDecoration: 'none' }}>
                  <Card
                    sx={{
                      backgroundColor: '#fcfcfc',
                      color: '#000000',
                      textAlign: 'center',
                      padding: '16px 12px',
                      ':hover': { backgroundColor: '#f5f5f5' },
                      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
                      borderRadius: '8px', // Rounded corners
                      transition: 'all 0.3s ease-in-out',
                      height: {
                        xs: '120px',
                        sm: '150px',
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                      }}
                    >
                      <Typography variant="h5" sx={{ marginBottom: '12px' }}>
                        <ImageFilter
                          image={card.image}
                          filter="duotone"
                          colorOne={[180, 20, 147]}
                          colorTwo={[139, 0, 139]}
                          // colorOne={card.colorOne}
                          // colorTwo={card.colorTwo}
                          alt={card.alt}
                          style={{ width: '50px', height: '50px' }}
                        />

                      </Typography>
                      {card.count !== null && (
                        <>
                          {/* <Typography
                                            variant="body1"
                                            sx={{
                                              fontSize: '14px',
                                              color: '#2c3e50',
                                              fontWeight: '500',
                                              marginBottom: '4px', // Ensure spacing consistency
                                            }}
                                          >
                                            {card.count}
                                          </Typography> */}
                        </>
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '14px',
                          fontWeight: '700',
                          color: '#2c3e50',
                          marginTop: "10px"
                        }}
                      >
                        {card.text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}



    </ThemeProvider >
  );
}


ExaminationManagement.propTypes = {};

ExaminationManagement.defaultProps = {};

export default ExaminationManagement;
