import React, { useState } from 'react'
import PropTypes from 'prop-types'
import '../MainComponent/MainComponent.scss'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Canvas } from '@react-three/fiber'
import { Sphere, Text } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import {
  useMediaQuery,
  Card,
  CardActions,
  CardContent,
  TextField,
  Container,
  Modal
} from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Typography, Chip } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import LoginForm from '../LoginForm/LoginForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPython,
  faReact,
  faAndroid,
  faAppStoreIos
} from '@fortawesome/free-brands-svg-icons'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import CloseIcon from '@mui/icons-material/Close'

import School from '@mui/icons-material/School'
import Group from '@mui/icons-material/Group'
import Assignment from '@mui/icons-material/Assignment'
import Face from '@mui/icons-material/Face'
import AccountBalance from '@mui/icons-material/AccountBalance'
import Print from '@mui/icons-material/Print'
import Balance from '@mui/icons-material/AccountBalanceWallet'
import Description from '@mui/icons-material/Description'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Tabs, Tab, styled } from '@mui/material'
import { Email, LocationOn, Phone } from '@mui/icons-material'

import { AppBar, Toolbar } from '@mui/material'
import {
  ThumbUp,
  Devices,
  Cloud,
  BarChart,
  Headset,
  Speed,
  Margin
} from '@mui/icons-material'

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  },
  '& .MuiInputLabel-root': {
    color: 'white'
  },
  '& .MuiOutlinedInput-input': {
    color: 'white'
  }
})

const all_features = [
  {
    title: 'Institute Info',
    description:
      'You can set your all institute info like logo, name, target line ect, which will display on every printable documents and reports.',
    icon: School
  },
  {
    title: 'Class Management',
    description:
      'This school management software manage your classes in an easy way. starts from students to subjects, courses to marks.',
    icon: Devices
  },
  {
    title: 'Exams Management',
    description:
      'Littlesteps has a complete solution for exams management starting from new exam to final result, reports and result cards.',
    icon: Cloud
  },
  {
    title: 'Attendance System',
    description:
      'Our free school software has outstanding online attendance management system for students and staff.',
    icon: BarChart
  },
  {
    title: 'Fee Management',
    description:
      'To manage fees and dues, our school software creates an account for each student. Littlesteps takes care of everything on its own.',
    icon: Headset
  },
  {
    title: 'Tests Management',
    description:
      'Managing class tests is a piece of cake with this free school management software. It keeps record of every class test.',
    icon: Speed
  },
  {
    title: 'Accounts',
    description:
      'Managing income, expense, and staff salaries is no more difficult. By using our software you can manage quite easly.',
    icon: Speed
  },
  {
    title: 'Printable Reports',
    description:
      'You can print all the reports and letters like, admission letter, fee slip, salary slip, job letter and result cards etc.',
    icon: Speed
  }
]

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#5c97c3'
    },
    dark: {
      main: '#111111'
    },
    background: {
      default: '#081727',
      paper: '#081727',
      warning: '#f5c44e'
    }
  }
})

function FeatureCard ({ feature, align }) {
  // feature card of upside element first
  const Icon = feature.icon
  return (
    <Card
      sx={{ bgcolor: '#09192a', backdropFilter: 'blur(10px)' }}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Box
          sx={{
            bgcolor: '#212f3d',
            borderRadius: '50%',
            p: 1,
            mb: 2,
            height: '50px',
            width: '50px',
            justifyContent: 'center',
            textAlign: 'center',
            alignItems: 'center'
          }}
        >
          <Icon sx={{ color: '#1976d3' }} />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h4' component='h4' color='white' gutterBottom>
            {feature.title}
          </Typography>
        </Box>
        <Typography variant='body1' color='white'>
          {feature.description}
        </Typography>
      </CardContent>
    </Card>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginBottom: '210px',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 6,
  color: '#000000'
}

const ImageSwitcher = () => {
  // State to track the current image
  const [currentImage, setCurrentImage] = useState(
    '/img_frontend/feature_mobile.png'
  )

  // Function to change image
  const changeImage = () => {
    const newImage =
      currentImage === '/img_frontend/feature_mobile.png'
        ? '/img_frontend/feature_mobile_2.png' // Second image path
        : '/img_frontend/feature_mobile.png' // First image path
    setCurrentImage(newImage)
  }
  return (
    <>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={changeImage}
        sx={{
          marginBottom: '20px',
          marginBottom: '20px',
          right: '-320px',

          display: 'flex', // Makes the button take full width
          width: '10%', // Ensures it spans the container width
          maxWidth: '300px', // Optional: limit maximum width
          margin: '0 auto', // Centers the button horizontally
        }}
      > {<ArrowBackIosIcon />}Change
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={changeImage}
        sx={{
          marginBottom: '20px',
          marginBottom: '20px',
          right: '-750px',

          display: 'flex', // Makes the button take full width
          width: '10%', // Ensures it spans the container width
          maxWidth: '300px', // Optional: limit maximum width
          margin: '0 auto', // Centers the button horizontally

        }}
      > Change{<ArrowForwardIosIcon />}
      </Button> */}
    </>
  )
}

const MainComponent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const features = [
    {
      title: 'Absolutely Free',
      description:
        'You can set your all institute info like logo, name, target line ect, which will display on every printable documents and reports.',
      icon: ThumbUp
    },
    {
      title: 'Cloud Based Software',
      description:
        'This school management software manage your classes in an easy way, starts from students to subjects, courses to marks.',
      icon: Group
    },
    {
      title: 'Regular Updates & Support',
      description:
        'From new exams to final results, reports, and result cards, Littlesteps offers a exam management system.',
      icon: Assignment
    },
    {
      title: 'Fast, Secure & Easy',
      description:
        'Our free school software has outstanding online attendance management system for students and staff.',
      icon: Face
    },
    {
      title: 'Infographics & Animations',
      description:
        'Our school software opens an account for every student to manage its fees and dues.',
      icon: AccountBalance
    },
    {
      title: 'Responsive Web Design',
      description:
        'Managing class tests is a piece of cake with this free school management software. It keeps record of every class test.',
      icon: Print
    }
    // {
    //   title: 'Accounts',
    //   description: 'Managing income, expense, and staff salaries is no more difficult. By using our software you can manage quite easily.',
    //   icon: Balance,
    // },
    // {
    //   title: 'Printable Reports',
    //   description: 'You can print all the reports and letters like, admission letter, fee slip, salary slip, job letter and result cards etc.',
    //   icon: Description,
    // },
  ]

  function TabPanel ({ children, value, index }) {
    return (
      <Box sx={{ bgcolor: '#0A2472', minHeight: '100vh', py: 8 }}>
        <Container maxWidth='lg'>
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Typography variant='h2' component='h1' color='white' gutterBottom>
              Drop us a Line.
            </Typography>
            <Typography variant='h5' color='white' sx={{ opacity: 0.8 }}>
              Ask us a question, or just say Hello.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
            <Box component='form' sx={{ flex: '1 1 600px' }}>
              {/* onSubmit={handleSubmit}  */}
              <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <StyledTextField
                  required
                  fullWidth
                  label='First name'
                  variant='outlined'
                  name='firstName'
                />
                <StyledTextField
                  required
                  fullWidth
                  label='Last name'
                  variant='outlined'
                  name='lastName'
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <StyledTextField
                  required
                  fullWidth
                  label='Email'
                  variant='outlined'
                  type='email'
                  name='email'
                />
                <StyledTextField
                  fullWidth
                  label='Phone'
                  variant='outlined'
                  name='phone'
                />
              </Box>
              <StyledTextField
                required
                fullWidth
                label='Message'
                variant='outlined'
                multiline
                rows={6}
                name='message'
                sx={{ mb: 3 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type='submit'
                  variant='contained'
                  size='large'
                  sx={{
                    bgcolor: '#1976d2',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#1565c0'
                    }
                  }}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
            {/* onChange={handleChange} */}
            <Box sx={{ flex: '1 1 300px' }}>
              <Tabs
                value={value}
                //handle change will here appear
                aria-label='contact tabs'
                sx={{
                  '& .MuiTab-root': { color: 'white' },
                  '& .Mui-selected': { color: '#90caf9' },
                  '& .MuiTabs-indicator': { bgcolor: '#90caf9' }
                }}
              >
                <Tab icon={<Email />} label='Email' />
                <Tab icon={<LocationOn />} label='Location' />
                <Tab icon={<Phone />} label='Phone' />
              </Tabs>
              <forms value={value} index={0}>
                Contact us by email
                <Typography component='div' sx={{ color: '#90caf9', mt: 1 }}>
                  mail@example.com
                </Typography>
              </forms>
              <TabPanel value={value} index={1}>
                123 Business Street
                <Typography component='div' sx={{ color: '#90caf9', mt: 1 }}>
                  New York, NY 10001
                </Typography>
              </TabPanel>
              <TabPanel value={value} index={2}>
                Call us at
                <Typography component='div' sx={{ color: '#90caf9', mt: 1 }}>
                  +1 (555) 123-4567
                </Typography>
              </TabPanel>

              <Box
                component='img'
                src='/placeholder.svg?height=300&width=600'
                alt='City illustration'
                sx={{
                  width: '100%',
                  height: 'auto',
                  mt: 4
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    )
  }
  return (
    <>
      <div className='wave'></div>
      <div className='hero'>
        <Box
          sx={{
            background: '#3c83f6'
          }}
        >
          <Container maxWidth='lg' sx={{ mt: 0, mb: 4 }}>
            <Typography
              align='center'
              color='white'
              gutterBottom
              sx={{
                fontWeight: 400, // Default font weight
                fontSize: { xs: '20px', sm: '28px', md: '40px', lg: '50px' }, // Responsive font sizes
                whiteSpace: 'normal', // Allow text to wrap in smaller screens
                overflow: 'visible', // Ensure text is not clipped
                textOverflow: 'clip', // Prevent ellipsis
                // Responsive top margin
                px: 2 // Padding to avoid clipping at screen edges
              }}
            >
              <Box component='span'>Features Of School Management Software</Box>
            </Typography>
            <Typography
              align='center'
              color='white'
              paragraph
              sx={{
                maxWidth: '1000px',
                mx: 'auto', // Center the element
                fontSize: { xs: '16px', sm: '18px', md: '20px' }, // Responsive font size
                fontWeight: 3000,
                lineHeight: 2, // Adjust line height for better readability
                textAlign: 'center'
                // Move the text upwards by a negative margin
              }}
            >
              littlestep is a complete and feature-rich school management
              software for all educational institutes. This school management
              software is for learning, administration, and management
              activities in schools, colleges, universities, tuition centers, or
              training centers. Our free school management system manages
              everything starting from admission to attendance and exams to
              result in cards.
            </Typography>
          </Container>

          <Container maxWidth='100%' sx={{ mt: 8 }}>
            <Grid container spacing={4} alignItems='center'>
              <Grid item xs={12} md={4}>
                <Grid container direction='column' spacing={4}>
                  {features.slice(0, 3).map((feature, index) => (
                    <Grid item key={index}>
                      <FeatureCard
                        feature={feature}
                        align={isMobile ? 'left' : 'right'}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {/* <Button
                variant="contained"
                color="primary"
                onClick={changeImage}
                sx={{ marginBottom: '20px' }}
              >
                Change Image
              </Button> */}
                <ImageSwitcher />
                <Box
                  component='img'
                  src='/img_frontend/feature_mobile.png' //image of mid
                  alt='Littlesteps App Interface'
                  sx={{
                    width: "100%",
                    height: 'auto',
                    boxShadow: 3,
                    borderRadius: '8px', // Add rounded corners
                    boxShadow: 0,

                    transition: 'all 0.5s ease-in-out' // Smooth transition for image change
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container direction='column' spacing={4}>
                  {features.slice(3).map((feature, index) => (
                    <Grid item key={index + 3}>
                      <FeatureCard feature={feature} align='left' />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <div className='wave-yellow mt-5'></div>
        <ThemeProvider theme={theme}>
          <Box sx={{ bgcolor: '#7ec72a', minHeight: '100vh' }}>
            {/* Main Content */}
            <Container maxWidth='lg' sx={{ py: 8 }}>
              {/* Header */}
              <Box
                sx={{
                  textAlign: 'center',
                  mb: 8,
                  width: '100%' // Ensures the Box takes full width
                }}
              >
                <Typography
                  variant='h3'
                  component='h1'
                  sx={{
                    color: '#08192a !important',
                    fontWeight: 'bold',
                    mb: 4
                  }}
                >
                  All Features in one place
                </Typography>
              </Box>

              {/* Features Grid */}
              <Grid container spacing={4}>
                {all_features.map((feature, index) => {
                  const IconComponent = feature.icon
                  return (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <Card>
                        <CardContent
                          sx={{
                            textAlign: 'center', // Center-align the text
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center-align items horizontally
                            justifyContent: 'center' // Center-align items vertically
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex', // Flexbox for centering
                              justifyContent: 'center',
                              alignItems: 'center',
                              p: 2,
                              borderRadius: '50%',
                              bgcolor: 'rgba(255, 255, 255, 0.1)',
                              mb: 2
                            }}
                          >
                            <IconComponent
                              sx={{
                                fontSize: 48, // Icon size
                                color: '#1976d2' // Icon color
                              }}
                            />
                          </Box>
                          <Typography
                            variant='h6'
                            component='h2'
                            sx={{
                              color: 'white',
                              mb: 2,
                              fontWeight: 'medium'
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography
                            variant='body1'
                            sx={{
                              color: 'rgba(255, 255, 255, 0.8)',
                              lineHeight: 1.6
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Container>
          </Box>
        </ThemeProvider>
      </div>
      {/* <Button color="inherit">Products</Button>
      <Button color="inherit">Help</Button> */}
    </>
  )
}

MainComponent.propTypes = {}

export default MainComponent
