import React from 'react';
import './Footer.scss';
import {
  Box, Container,
  Grid,
  Typography,

  Stack,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CottageIcon from '@mui/icons-material/Cottage';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { useAuth } from '../../services/authContext';
import { Link, useLocation } from "react-router-dom";
import {
  Facebook,
  Twitter,
  YouTube,
  Google,
  Apple,
  PlayArrow,
} from '@mui/icons-material';

const Footer = () => {
  const [value, setValue] = React.useState(0);

  const { loginStatus } = useAuth();
  const location = useLocation();

  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: { main: '#e4b648' },
      warning: { main: '#e4b648' }
      // Add more theme customizations as needed
    },
  });

  React.useEffect(() => {
    switch (location.pathname) {
      case '/add_user':
        setValue(2);  // New Client page
        break;
      case '/settings':
        setValue(1);  // Settings page
        break;
      default:
        setValue(0);  // Home page
        break;
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      {!loginStatus.token ?
        <Box sx={{ bgcolor: '#0A1929', color: 'white', py: 6 }}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              {/* Logo and Description */}
              <Grid item xs={12} md={4}>
                <Box sx={{ mb: 2 }}>
                  <img
                    src="/LOGO-IN-CIRCLE-PNG.png"
                    alt="eSkooly Logo"
                    width={100}
                    height={100}
                  />
                </Box>
                <Typography variant="body2" sx={{ color: 'grey.400', mb: 2 }}>
                  Littlesteps is the world's best and #1 ranked free online school management software.
                  Our school management software has more features than any school software in the market.
                </Typography>
              </Grid>

              {/* ESKOOLY Links */}
              <Grid item xs={12} sm={6} md={2}>

                <Typography variant="subtitle1" sx={{ mb: 2, color: 'white' }}>
                  Littlesteps
                </Typography>
                <Stack spacing={2}>
                  <Link href="#" underline="hover" >
                    Home
                  </Link>
                  <Link href="#" underline="hover" sx={{ color: 'blue' }}>
                    Pricing
                  </Link>
                  <Link href="#" underline="hover" sx={{ color: 'blue' }}>
                    Get started
                  </Link>
                  <Link href="#" underline="hover" sx={{ color: 'blue' }}>
                    Help
                  </Link>
                </Stack>
              </Grid>

              {/* TERMS Links */}
              <Grid item xs={12} sm={6} md={2}>
                <Typography variant="subtitle1" sx={{ mb: 2, color: 'white' }}>
                  TERMS
                </Typography>
                <Stack spacing={2}>
                  <Link href="#" underline="hover" sx={{ color: '#1E90FF' }}>
                    Terms of Service
                  </Link>
                  <Link href="#" underline="hover" sx={{ color: 'blue' }}>
                    Privacy policy
                  </Link>
                  <Link href="#" underline="hover" sx={{ color: '#0000FF' }}>
                    SaaS services
                  </Link>
                </Stack>
              </Grid>
              {/* Download Buttons */}
              <Grid item xs={12} md={4}>
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ mb: 3 }}>
                  <Button
                    variant="contained"
                    startIcon={<PlayArrow />}
                    fullWidth
                    sx={{
                      bgcolor: '#3fad4a',
                      '&:hover': { bgcolor: '#3fad4a' },
                      borderRadius: 2,
                      py: 1.5
                    }}
                  >
                    <Box sx={{ textAlign: 'left', ml: 1 }}>
                      <Typography variant="caption" display="block" sx={{ lineHeight: 1 }}>
                        GET IT ON
                      </Typography>
                      <Typography variant="subtitle2">
                        GOOGLE PLAY
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<Apple />}
                    fullWidth
                    sx={{
                      bgcolor: '#3B82F6',
                      '&:hover': { bgcolor: '#2563EB' },
                      borderRadius: 2,
                      py: 1.5
                    }}
                  >
                    <Box sx={{ textAlign: 'left', ml: 1 }}>
                      <Typography variant="caption" display="block" sx={{ lineHeight: 1 }}>
                        DOWNLOAD ON THE
                      </Typography>
                      <Typography variant="subtitle2">
                        APP STORE
                      </Typography>
                    </Box>
                  </Button>
                </Stack>

                {/* Social Media Icons */}
                <Stack direction="row" spacing={1} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                  <IconButton size="small" sx={{ color: 'grey.400' }}>
                    <Facebook />
                  </IconButton>
                  <IconButton size="small" sx={{ color: 'grey.400' }}>
                    <Twitter />
                  </IconButton>
                  <IconButton size="small" sx={{ color: 'grey.400' }}>
                    <Google />
                  </IconButton>
                  <IconButton size="small" sx={{ color: 'grey.400' }}>
                    <YouTube />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
            <Typography variant="caption" sx={{ color: 'grey.500', textAlign: 'center', display: 'flex', flexWrap: 'wrap', alignSelf: 'center', justifySelf: "center", marginTop: "30px" }}>
              Copyright © 2025 Littlesteps Pvt Ltd. - All rights reserved.
            </Typography>
          </Container>
        </Box>
        :
        <div>
          {loginStatus.user.is_superuser ?
            <Box sx={{ flexGrow: 1, background: "#0d2238" }}>
              <BottomNavigation
                showLabels
                value={value}
                className='bn'
                sx={{ backgroundColor: "#0d2238 !important", zIndex: 1 }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Home"
                  icon={<CottageIcon />}
                  component={Link}
                  to="/"
                  sx={{
                    color: value === 0 ? 'white !important' : 'white !important', // Default white, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
                <BottomNavigationAction
                  label="Settings"
                  icon={<SettingsIcon />}
                  component={Link}
                  to="/settings"
                  sx={{
                    color: value === 1 ? 'white !important' : 'white !important', // Default white, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
                <BottomNavigationAction
                  label="Timetable"
                  icon={<PunchClockIcon />}
                  component={Link}
                  to="/teacherTimetable"
                  sx={{
                    color: value === 0 ? 'white !important' : 'white !important', // Default white, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
              </BottomNavigation>
            </Box>
            :
            <Box sx={{ flexGrow: 1, background: "#0d2238" }}>
              <BottomNavigation
                showLabels
                value={value}
                className='bn'
                sx={{ backgroundColor: "#0d2238 !important" }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              >
                <BottomNavigationAction
                  label="Home"
                  icon={<CottageIcon />}
                  component={Link}
                  to="/"
                  sx={{
                    color: value === 0 ? 'white !important' : 'white !important', // Default white, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
                <BottomNavigationAction
                  label="Timetable  "
                  icon={<PunchClockIcon />}
                  component={Link}
                  to="/teacherTimetable"
                  sx={{
                    color: value === 0 ? 'white !important' : 'white !important', // Default white !important, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
                <BottomNavigationAction
                  label="Settings"
                  icon={<SettingsIcon />}
                  component={Link}
                  to="/settings"
                  sx={{
                    color: value === 1 ? 'white !important' : 'white !important', // Default white !important, active #5c97c3
                    '&.Mui-selected': {
                      color: '#5c97c3',
                    }
                  }}
                />
              </BottomNavigation>
            </Box>
          }
        </div>
      }
    </ThemeProvider>
  );
};

Footer.propTypes = {};

export default Footer;
