import React, { useState } from 'react'
import './Header.scss'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { useAuth } from '../../services/authContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Initial from '../Initial/Initial'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  TextField,
  Chip,
  Modal,
  useMediaQuery
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close'
import SignupForm from '../SignupForm/SignupForm' // Assuming SignupForm is in the same directory
import LoginForm from '../LoginForm/LoginForm'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white !important',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 6,
  color: '#000000'
}

const drawerWidth = 240
const navItems = ['Login', 'Sign Up']

const navItemsLogout = ['Home']

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#081727' // Fallback solid color
    },
    warning: {
      main: '#f5c44e'
    },
    background: {
      default: '#f5f5f5',
      paper: '#e0e0e0'
    }
  },
  customGradients: {
    primaryGradient: 'linear-gradient(to right, #081727, #3b5998)' // Define your gradient here
  }
})

const Header = props => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [value, setValue] = React.useState(0)
  const { loginStatus } = useAuth()
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [showSignupForm, setShowSignupForm] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [otp, setOtp] = React.useState('')
  const [step, setStep] = React.useState(1) // Step 1: Phone Input, Step 2: OTP Input

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }

  const [signupOpen, setSignupOpen] = React.useState(false) // State for signup modal


  const handleSignupOpen = () => setSignupOpen(true)
  const handleSignupClose = () => {
    setShowSignupForm(false)
    setStep(1) // Reset to phone input step if applicable
  }

  const handlePhoneSubmit = () => {
    if (phoneNumber.trim()) {
      console.log(`Sending OTP to ${phoneNumber}`)
      setStep(2)
    }
  }

  const handleOtpSubmit = () => {
    if (otp.trim()) {
      console.log(`Validating OTP: ${otp}`)
      handleClose()
    }
  }

  const container =
    window !== undefined ? () => window().document.body : undefined
  const handleOpen = () => {
    setShowSignupForm(true)
  }
  const handleClose = () => {
    setOpen(false)
    setStep(1) // Reset to phone input step
  }

  const handleNavClick = item => {
    if (item === 'Sign Up') {
      setShowSignupForm(true);
    } else if (item === 'Login') {
      // Add your login functionality or navigation here if needed
     setOpen(true);
    }
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <img
          src='https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png'
          alt='Logo'
          className='topLogo'
        />
      </Typography>
      <Divider />
      <List>
        {!loginStatus.token ? (
          <>
            {navItems.map(item => (
              <ListItem
                key={item}
                disablePadding
                onClick={() => handleNavClick(item)}
              >
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        ) : (
          <>
            {navItemsLogout.map(item => (
              <ListItem key={item} disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </Box>
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        {!loginStatus.token ? (
          <div className='container'>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar
                component='nav'
                sx={{ background: '#081727', boxShadow: 'none', zIndex: 5000 }}
              >
                <Toolbar className='container'>
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <img
                      src='https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png'
                      alt='Logo'
                      style={{ width: '56px', height: '56px' }} // Adjust size as needed
                    />
                  </IconButton>
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                  >
                    <img
                      src='https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png'
                      style={{ width: '76px', height: '76px', padding: '10px' }}
                      alt='Logo'
                      className=''
                    />
                  </Typography>

                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {navItems.map(item => (
                      <Button
                        variant='contained'
                        color='warning'
                        key={item}
                        sx={{ marginLeft: '30px' }}
                        onClick={() => handleNavClick(item)}
                      >
                        {item}
                      </Button>
                    ))}
                  </Box>
                </Toolbar>
              </AppBar>
              <nav>
                <Drawer
                  container={container}
                  variant='temporary'
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }}
                  sx={{
                    display: { sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                      boxSizing: 'border-box',
                      width: drawerWidth
                    }
                  }}
                >
                  {drawer}
                </Drawer>
              </nav>
            </Box>
          </div>
        ) : (
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component='nav' sx={{ background: '#081727' }}>
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  edge='start'
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <img
                    src='https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png'
                    alt='Logo'
                    style={{ width: '35px', height: '35px' }} // Adjust size as needed
                  />
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      marginLeft: '12px'
                    }}
                  >
                    LittleSteps
                  </Typography>
                </IconButton>
                <Typography
                  variant='h6'
                  component='div'
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                >
                  <img
                    src='https://littlestep.in/wp-content/uploads/2024/10/LOGO-IN-CIRCLE-PNG.png'
                    style={{ width: '35px', height: '35px' }}
                    alt='Logo'
                    className='topLogo'
                  />
                </Typography>

                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  {navItemsLogout.map(item => (
                    <Button key={item} sx={{ color: '#fff' }}>
                      {item}
                    </Button>
                  ))}
                  <Button sx={{ color: theme.palette.warning.main }}>
                    <Chip
                      avatar={
                        <Avatar color={theme.palette.warning.main}>
                          {loginStatus.user.first_name.split('')[0]}
                        </Avatar>
                      }
                      label={loginStatus.user.first_name}
                      sx={{ color: theme.palette.warning.main }}
                    />
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            <nav>
              <Drawer
                container={container}
                variant='temporary'
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                  display: { sm: 'block', md: 'none' },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    width: drawerWidth
                  }
                }}
              >
                {drawer}
              </Drawer>
            </nav>
          </Box>
        )}
      </ThemeProvider>

      <Modal
        fullscreen
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ ...style, position: 'relative', background: "#ffffff" }}>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              cursor: 'pointer'
            }}
          />
          <LoginForm />
        </Box>
      </Modal>

      <Modal
        fullscreen
        open={showSignupForm}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={{ ...style, position: 'relative' }}>
          <CloseIcon
            onClick={handleSignupClose}
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              cursor: 'pointer'
            }}
          />
          <SignupForm />
        </Box>
      </Modal>
    </>
  )
}

Header.propTypes = {
  window: PropTypes.func
}

export default Header
