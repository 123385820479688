import React from 'react'
import PropTypes from 'prop-types'
import './LoginForm.scss'
import {
  Card,
  CardActions,
  CardContent,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  Link
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useAuth } from '../../services/authContext'
import {
  LoginApi,
  FetchUsernamesApi,
  ForgetPassscodeApi
} from '../../services/apis'
import PasscodeInput from '../PasscodeInput/PasscodeInput'
import CryptoJS from 'crypto-js'
import secureLocalStorage from 'react-secure-storage'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
  }
})

const LoginForm = () => {
  const { setLoginStatus } = useAuth()
  const [phoneNumber, setPhoneNumber] = React.useState('') // Phone number input
  const [users, setUsers] = React.useState([]) // List of users
  const [selectedUser, setSelectedUser] = React.useState('') // Selected username
  const [passcode, setPasscode] = React.useState('') // Passcode input
  const [loading, setLoading] = React.useState(false) // Track loading status for login
  const [step, setStep] = React.useState(1) // Step 1: Phone input, Step 2: Username + Passcode
  const [forgetPass, setForgetPass] = React.useState(false)

  function generateRandomString (length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
  }

  const handleForgetPasscode = (selUser) => {
    setLoading(true) // Start loading indicator
    ForgetPassscodeApi({ phone: selUser })
      .then(response => {
        alert('OTP sent successfully!')
        setLoading(false) // Stop loading indicator
      })
      .catch(error => {
        alert('Error sending OTP. Please try again.')
        setLoading(false) // Stop loading indicator on error
      })
  }

  function validatePhoneNumber (phoneNumber) {
    // Remove leading/trailing whitespace
    phoneNumber = phoneNumber.trim()

    // 1. Check if empty
    if (phoneNumber.length === 0) {
      alert('Please enter a phone number')
      return false
    }

    // 2. Check for numeric values (allow '+' at the start for country codes)
    const numericPattern = /^[+]?[0-9]+$/
    if (!numericPattern.test(phoneNumber)) {
      alert(
        "Phone number must contain only numbers or an optional '+' at the start"
      )
      return false
    }

    // 3. Check length (e.g., typical lengths: 10-15 digits)
    const minLength = 10 // Adjust as needed
    const maxLength = 15 // Adjust as needed
    const strippedNumber = phoneNumber.replace(/^\+/, '') // Remove leading '+'
    if (
      strippedNumber.length < minLength ||
      strippedNumber.length > maxLength
    ) {
      alert(`Phone number must be between ${minLength} and ${maxLength} digits`)
      return false
    }

    // 4. Check valid start (e.g., disallow starting with '0' if needed)
    if (!phoneNumber.startsWith('+') && strippedNumber.startsWith('0')) {
      alert("Phone number should not start with 0 (unless it's a valid format)")
      return false
    }

    // 5. All validations passed
    return true
  }

  const handleForgetPasscodeSetup = selUser => {
    debugger;
    setForgetPass(true) // Start loading indicator
    handleForgetPasscode(selUser)
  }

  // Fetch users when login button is clicked
  const handleFetchUsers = () => {
    if (!phoneNumber) {
      alert('Please enter a phone number')
      return
    }
    setLoading(true)
    FetchUsernamesApi({ phone: phoneNumber }) // Replace with actual API call
      .then(response => {
        const fetchedUsers = response.users || []
        if (fetchedUsers.length > 0) {
          setUsers(fetchedUsers)
          setSelectedUser(fetchedUsers[0].username) // Set the first user as default
          setStep(2) // Move to step 2: Username and passcode
        } else {
          alert('No users found for this phone number.')
        }
        setLoading(false)
      })
      .catch(error => {
        alert('Error fetching users. Please try again.')
        setLoading(false)
      })
  }

  // Handle login with selected username and passcode
  const handleLogin = () => {
    if (!selectedUser) {
      alert('Please select a user.')
      return
    }

    setLoading(true) // Start loading indicator
    LoginApi({
      phone: phoneNumber,
      username: selectedUser,
      passcode
    }) // Include username in the API request
      .then(response => {
        // Utility function to generate a random string

        // Dynamically generated value
        const efefrg4rrrg323ffgfssw3e3ddadfw23 = generateRandomString(256) // Adjust length as needed
        secureLocalStorage.setItem('key', efefrg4rrrg323ffgfssw3e3ddadfw23)
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(response),
          secureLocalStorage.getItem('key') // Replace with a secure key
        ).toString() // Encrypt the response data

        localStorage.setItem('loginCred', encryptedData) // Store encrypted data
        window.location.reload()
      })
      .catch(error => {
        console.log('yo yo', error)
        alert('Invalid username or passcode. Please try again.')
        setLoading(false) // Stop loading indicator on error
      })
  }

  const LoginCard = (
    <React.Fragment sx={{background: '#ffffff'}}>
      <CardContent sx={{background: "#ffffff"}}>
        <Typography
          variant='h5'
          component='h4'
          sx={{ color: theme.palette.primary.main }}
        >
          Already Enrolled? Login Below to Book Classes, Check Attendance,
          Recordings, and More!
        </Typography>
        {/* Step 1: Phone Number Input */}
        {step === 1 && (
          <Typography variant='h6' component='div'>
            <Box
              sx={{ flexGrow: 1, mx: 2, textAlign: 'left', marginTop: '30px' }}
            >
              <label>Phone Number</label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Phone Number'
                fullWidth
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </Box>
          </Typography>
        )}
        {/* Step 2: Username and Passcode */}
        {step === 2 && (
          <Typography variant='h6' component='div'>
            <Box
              sx={{ flexGrow: 1, mx: 2, textAlign: 'left', marginTop: '15px' }}
            >
              <label>Select User</label>
              <Select
                fullWidth
                value={selectedUser}
                onChange={e => setSelectedUser(e.target.value)}
                displayEmpty
                sx={{
                  mb: 2,
                  color: 'black' // Ensure dropdown text is black
                }}
              >
                {users.map(user => (
                  <MenuItem
                    key={user.id}
                    value={user.username}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      padding: '12px 16px',
                      color: '#000000',
                      '&:hover': {
                        backgroundColor: '#eaf2fc'
                      }
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: '16px',
                        color: '#333'
                      }}
                    >
                      {user.first_name} {user.last_name}
                    </span>
                    <span style={{ fontSize: '14px', color: '#666' }}>
                      ({user.username})
                    </span>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#4a90e2',
                        marginTop: '4px'
                      }}
                    >
                      {user.staff_type.toUpperCase()}
                    </span>
                  </MenuItem>
                ))}
              </Select>

              <label>Enter Passcode</label>
              <PasscodeInput
                passcode={passcode.split('')} // Convert string to array
                setPasscode={setPasscode}
              />
            </Box>
          </Typography>
        )}
      </CardContent>
      <CardActions
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          background: '#ffffff'
        }}
      >
        {/* Step 1: Login Button */}
        {step === 1 && (
          <Button
            variant='contained'
            className='btn'
            onClick={handleFetchUsers}
            sx={{background: "#3b82f6"}}
            disabled={loading || !phoneNumber} // Disable if loading or phone number is empty
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: '#fff' }} />
            ) : (
              'Login'
            )}
          </Button>
        )}
        {/* Step 2: Verify Button */}
        {step === 2 && (
          <>
            <Button
              variant='contained'
              className='btn'
              onClick={handleLogin}
              disabled={loading || !selectedUser || !passcode} // Disable if loading or fields are empty
              sx={{background: "#3b82f6"}}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: '#fff' }} />
              ) : (
                'Verify'
              )}
            </Button>
            <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
              <Link
                onClick={() => handleForgetPasscodeSetup(selectedUser)}
                variant='body2'
                sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
              >
                Forget Passcode?
              </Link>
            </Box>
          </>
        )}
      </CardActions>
    </React.Fragment>
  )

  return (
    <div className='LoginForm' data-testid='LoginForm'>
      <Box
        sx={{
          minWidth: 275,
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
        className='perfect-center'
      >
        <Card variant='outlined'>{LoginCard}</Card>
      </Box>
    </div>
  )
}

LoginForm.propTypes = {}

export default LoginForm
