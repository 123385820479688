import React, { useRef, useState } from 'react'
import './Initial.scss'
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Modal,
  TextField,
  Paper,
  CardContent,
  CardActions
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Canvas, useFrame } from '@react-three/fiber' // React-three/fiber for 3D rendering
import { a } from '@react-spring/three' // For animations
import PhoneIcon from '@mui/icons-material/PhoneAndroid'
import CloseIcon from '@mui/icons-material/Close'
import * as THREE from 'three'
import LoginForm from '../LoginForm/LoginForm'
import MainComponent from '../MainComponent/MainComponent'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import LoginIcon from '@mui/icons-material/Login'
import SignupForm from '../SignupForm/SignupForm'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CropDinIcon from '@mui/icons-material/CropDin';

const theme = createTheme({
  palette: {
    primary: { main: '#3b82f6' },
    background: { default: '#1e293c' },
    text: { primary: '#ffffff' }
  }
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 6,
  color: '#000000'
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#1c2b49',
  borderRadius: '15px',
  boxShadow: 24,
  color: '#ffffff',
  width: '90%',
  maxWidth: '400px',
  p: 4
}

const FeatureCard = ({ title, description }) => (
  <Paper
    elevation={3}
    sx={{
      padding: '20px',
      textAlign: 'center',
      background: '#1c2b49',
      color: '#ffffff'
    }}
  >
    <Typography variant='h6' gutterBottom>
      {title}
    </Typography>
    <Typography variant='body1'>{description}</Typography>
  </Paper>
)

const RotatingSphereWithImages = () => {
  const meshRef = useRef()
  const [images] = useState([
    '/LOGO-IN-CIRCLE-PNG.png',
    '/LOGO-IN-CIRCLE-PNG.png',
    '/LOGO-IN-CIRCLE-PNG.png' // Add the paths to your images
  ])

  const [angle, setAngle] = useState(0)

  // Create rotating images over the sphere
  const createRotatingImages = () => {
    return images.map((image, index) => {
      const angleOffset = (index / images.length) * Math.PI * 2
      return (
        <a.mesh
          key={index}
          rotation={[0, angle, 0]}
          position={[
            Math.cos(angleOffset + angle) * 6,
            0,
            Math.sin(angleOffset + angle) * 6
          ]}
        >
          {/* Increased size of the image (radius 3 instead of 2) */}
          <circleGeometry args={[2, 32]} /> {/* Increased radius to 3 */}
          <meshBasicMaterial
            attach='material'
            map={new THREE.TextureLoader().load(image)} // Ensuring texture is loaded
            transparent={false} // Remove transparency if not needed
          />
        </a.mesh>
      )
    })
  }

  // Add a square at the center of the sphere
  const createCenterSquare = () => {
    return (
      <a.mesh position={[0, 0, 0]}>
        <circleGeometry args={[4, 32]} />
        <meshBasicMaterial
          attach='material'
          map={new THREE.TextureLoader().load('/LOGO-IN-CIRCLE-PNG.png')} // The image to be used on the square
        />
      </a.mesh>
    )
  }

  // Continuously rotate the sphere and images
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.01 // Rotate the sphere
      setAngle(prevAngle => prevAngle + 0.01) // Rotate the images
    }
  })

  return (
    <>
      {/* Rotating Sphere */}
      <a.mesh ref={meshRef}>
        <sphereGeometry args={[5, 32, 32]} />
        <meshStandardMaterial color='white' wireframe={true} />
      </a.mesh>

      {/* Rotating Images */}
      {createRotatingImages()}

      {/* Square at the center of the sphere */}
      {createCenterSquare()}
    </>
  )
}

const Initial = () => {
  const [open, setOpen] = React.useState(false)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [otp, setOtp] = React.useState('')
  const [step, setStep] = React.useState(1) // Step 1: Phone Input, Step 2: OTP Input
  const [signupOpen, setSignupOpen] = React.useState(false) // State for signup modal

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setStep(1) // Reset to phone input step
  }

  const handleSignupOpen = () => setSignupOpen(true)
  const handleSignupClose = () => {
    setSignupOpen(false)
    setStep(1) // Reset to phone input step if applicable
  }

  const handlePhoneSubmit = () => {
    if (phoneNumber.trim()) {
      console.log(`Sending OTP to ${phoneNumber}`)
      setStep(2)
    }
  }

  const handleOtpSubmit = () => {
    if (otp.trim()) {
      console.log(`Validating OTP: ${otp}`)
      handleClose()
    }
  }

  const renderModalContent = () => (
    <Box sx={{ marginTop: '150px' }}>
      <Typography
        variant='h5'
        sx={{
          textAlign: 'center',
          mb: 2,
          color: theme.palette.primary.main,
          fontWeight: 'bold'
        }}
      >
        {step === 1 ? 'Sign in with Phone' : 'Enter OTP'}
      </Typography>

      <Typography variant='body2' sx={{ textAlign: 'center', mb: 3 }}>
        {step === 1
          ? 'Enter your phone number to receive an OTP.'
          : 'Please enter the OTP sent to your phone.'}
      </Typography>

      {step === 1 ? (
        <TextField
          fullWidth
          variant='outlined'
          size='small'
          label='Phone Number'
          placeholder='+91XXXXXXXXXX'
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          sx={{
            mb: 3,
            input: { color: '#ffffff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#ffffff' },
              '&:hover fieldset': { borderColor: theme.palette.primary.main }
            }
          }}
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            startAdornment: (
              <PhoneIcon
                sx={{ color: theme.palette.primary.main, marginRight: '8px' }}
              />
            )
          }}
        />
      ) : (
        <TextField
          fullWidth
          variant='outlined'
          size='small'
          label='OTP'
          placeholder='Enter OTP'
          value={otp}
          onChange={e => setOtp(e.target.value)}
          sx={{
            mb: 3,
            input: { color: '#ffffff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#ffffff' },
              '&:hover fieldset': { borderColor: theme.palette.primary.main }
            }
          }}
          InputLabelProps={{ style: { color: '#ffffff' } }}
        />
      )}

      <Button
        fullWidth
        variant='contained'
        color='primary'
        onClick={step === 1 ? handlePhoneSubmit : handleOtpSubmit}
      >
        {step === 1 ? 'Send OTP' : 'Verify OTP'}
      </Button>
    </Box>
  )

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          color: '#ffffff',
          minHeight: '100vh',
          width: '100%',
          marginTop: '82px'
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            textAlign: 'center',
            padding: { xs: '0px 20px', sm: '50px 20px' }
            
          }}
        >
          <Container>
            <div className='main-icons'>
              <ChangeHistoryIcon
                sx={{
                  position: 'absolute',
                  top: '30vH', // Start from the center of the container
                  left: '50%',
                  transform: 'translate(-10%, -10%)',
                  fontSize: { xs: '10px', sm: '20px' },
                  animation: 'revolve 5s linear infinite', // Add the revolution animation
                  '@keyframes revolve': {
                    '0%': {
                      transform: 'rotate(0deg) translate(10px) rotate(0deg)' // Rotate + translate outward
                    },
                    '100%': {
                      transform:
                        'rotate(360deg) translate(10px) rotate(-360deg)' // Reverse spin on its axis
                    }
                  }
                }}
              />
              <ChangeHistoryIcon
                sx={{
                  position: 'absolute',
                  top: '30vH',
                  left: '40vW',
                  color: '#662f99',
                  fontSize: { xs: '30px', sm: '50px' },
                  animation: 'spin 14s linear infinite', // Define animation
                  '@keyframes spin': {
                    // Define keyframes for rotation
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(360deg)' }
                  }
                }}
              />
              <ChangeHistoryIcon
                sx={{
                  position: 'absolute',
                  top: '10vH',
                  left: '20vW',
                  color: '#662f99',
                  fontSize: { xs: '30px', sm: '50px' },
                  animation: 'spin 40s linear infinite', // Define animation
                  '@keyframes spin': {
                    // Define keyframes for rotation
                    '0%': { transform: 'rotate(0deg)' },
                    '100%': { transform: 'rotate(360deg)' }
                  }
                }}
              />
              <ChangeHistoryIcon
                sx={{
                  position: 'absolute',
                  top: '20vH',
                  left: '50vW',
                  color: '#662f99',
                  fontSize: { xs: '30px', sm: '50px' },
                  animation: 'spin2 40s linear infinite', // Define animation
                  '@keyframes spin2': {
                    // Define keyframes for rotation
                    '0%': { transform: 'rotate(360deg)' },
                    '100%': { transform: 'rotate(0deg)' }
                  }
                }}
              />
              <RadioButtonUncheckedIcon
                sx={{
                  position: 'absolute',
                  top: '30vH', // Start from the center of the container
                  left: '70vW',
                  transform: 'translate(-10%, -10%)',
                  fontSize: { xs: '10px', sm: '20px' },
                  animation: 'revolve 3s linear infinite', // Add the revolution animation
                  '@keyframes revolve': {
                    '0%': {
                      transform: 'rotate(0deg) translate(15px) rotate(0deg)' // Rotate + translate outward
                    },
                    '100%': {
                      transform:
                        'rotate(360deg) translate(15px) rotate(-360deg)' // Reverse spin on its axis
                    }
                  }
                }}
              />
              <ChangeHistoryIcon
                sx={{
                  position: 'absolute',
                  top: '30vH', // Start position
                  left: '80vW',
                  transform: 'translate(-10%, -10%)',
                  fontSize: { xs: '10px', sm: '20px' },
                  animation: 'revolve 15s linear infinite', // Add the custom animation
                  '@keyframes revolve': {
                    '0%': {
                      transform: 'rotate(0deg) translate(10px)' // Initial state
                    },
                    '50%': {
                      transform: 'rotate(180deg) translate(30px)' // Midway, further from the center
                    },
                    '100%': {
                      transform: 'rotate(360deg) translate(10px)' // Back to the start
                    }
                  }
                }}
              />
              <CropDinIcon
                sx={{
                  position: 'absolute',
                  top: '10vH', // Start position
                  left: '70vW',
                  transform: 'translate(-10%, -10%)',
                  fontSize: { xs: '10px', sm: '20px' },
                  animation: 'revolve 10s linear infinite', // Add the custom animation
                  '@keyframes revolve': {
                    '0%': {
                      transform: 'rotate(0deg) translate(10px)' // Initial state
                    },
                    '50%': {
                      transform: 'rotate(180deg) translate(30px)' // Midway, further from the center
                    },
                    '100%': {
                      transform: 'rotate(360deg) translate(10px)' // Back to the start
                    }
                  }
                }}
              />
            </div>
            <Typography
              variant='h3'
              sx={{ marginBottom: '20px', zIndex: 3000, position: 'relative',  fontSize: { xs: '2rem', sm: '3rem' }, }}
            >
              Free Online{' '}
              <span style={{ color: theme.palette.primary.main }}>
                School Management
              </span>{' '}
              Software
            </Typography>
            <Typography
              variant='h6'
              sx={{ marginBottom: '30px', zIndex: 3000, position: 'relative' }}
            >
              Manage school operations like exams, attendance, and more for
              free.
            </Typography>
            <Box
              sx={{
                display: 'flex', // Use flexbox
                justifyContent: 'center', // Center the buttons horizontally
                alignItems: 'center', // Center the buttons vertically
                gap: '10px', // Add spacing between buttons
                flexWrap: 'wrap' // Allow wrapping on small screens
              }}
            >
              <Button
                variant='contained'
                color='primary'
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
                startIcon={<ArrowForwardIcon />} // Adds the arrow icon to the left
                onClick={handleSignupOpen}
              >
                Get Started Now
              </Button>
              <Button
                variant='outlined'
                sx={{
                  color: 'black', // Text color black
                  borderColor: '#ebbe4d', // Border color yellow
                  backgroundColor: '#ebbe4d', // Button background color yellow
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={handleOpen}
                startIcon={<LoginIcon />} // Adds the login icon to the left
              >
                Sign In
              </Button>
            </Box>
          </Container>

          {/* Rotating Big White Wireframe Sphere with Images */}
          <Box
            sx={{
              display: 'inline-block', // Makes the Box fit the content (image)
              position: 'relative', // Position relative to allow positioning the background
              maxWidth: '600px', // Increased width
              maxHeight: '600px', // Increased height
              backgroundColor: 'rgba(0, 0, 0, 0)', // Semi-transparent background (less opaque)
              borderRadius: '8px', // Rounded corners
              boxShadow: 0 // Optional shadow for the Box
            }}
          >
            {/* Image */}
            <Box
              component='img'
              src='/img_frontend/main_laptop.png' // Replace with your image URL
              alt='Sample Image'
              sx={{
                // marginTop: '-70px',// Moves the image upwards outside the container
                width: '70%', // Makes the image width 100% of its container
                height: '70%', // Makes the image height 100% of its container
                borderRadius: '8px', // Add rounded corners
                boxShadow: "0px 0px 0px rgba(0,0,0,1)",
                margin: "47px",
                animation: 'moveUpDown 2s infinite ease-in-out',
                '@keyframes moveUpDown': {
                  '0%, 100%': {
                    transform: 'translateY(0)' // Start and end at normal position
                  },
                  '50%': {
                    transform: 'translateY(-20px)' // Move the image upwards
                  }
                }
              }}
            />

            {/*<Box
              component='img'
              src='/img_frontend/main_mobile.png' // Replace with your mobile image URL
              alt='Mobile Image'
              sx={{
                position: 'absolute',
                bottom: 20, // Align mobile image to the bottom of the container
                right: '-260px',
                transform: 'translateX(-50%)', // Adjust position to truly center it
                width: '80%', // Adjust the mobile image width as needed
                height: 'auto', // Maintain aspect ratio
                animation: 'moveUpDown 2s infinite ease-in-out', // Animation to move up and down
                '@keyframes moveUpDown': {
                  '0%, 100%': {
                    transform: 'translateY(0)' // Start and end at normal position
                  },
                  '50%': {
                    transform: 'translateY(-20px)' // Move the image upwards
                  }
                },
                '@media (max-width: 600px)': {
                  // Hide on mobile screens (screen width <= 600px)
                  display: 'none'
                }
              }}
            />

            <Box
              component='img'
              src='/img_frontend/main_img_dec_1.png' // Replace with your mobile image URL
              alt='Management Image'
              sx={{
                maxWidth: '100px', // Increased width
                maxHeight: '100px', // Increased height
                position: 'absolute',
                bottom: 470, // Align mobile image to the bottom of the container
                right: '-120px', // Initial position to the right
                transform: 'translateX(-50%)', // Adjust position to truly center it
                width: '80%', // Adjust the mobile image width as needed
                height: 'auto', // Maintain aspect ratio
                animation: 'moveLeftRight 3s infinite ease-in-out', // Animation to move left and right smoothly
                '@keyframes moveLeftRight': {
                  '0%': {
                    transform: 'translateX(0)' // Start position (at normal position)
                  },
                  '50%': {
                    transform: 'translateX(20px)' // Move to the right at 50%
                  },
                  '100%': {
                    transform: 'translateX(0)' // Return to the original position (back to left)
                  }
                },

                '@media (max-width: 600px)': {
                  // Hide on mobile screens (screen width <= 600px)
                  display: 'none'
                }
              }}
            />

            {/*<Box
              component='img'
              src='/img_frontend/main_img_decc_2.png' // Replace with your mobile image URL
              alt='Management Image'
              sx={{
                maxWidth: '180px', // Increased width
                maxHeight: '180px', // Increased height
                position: 'absolute',
                right: '620px',
                transform: 'translateX(-50%)', // Adjust position to truly center it
                width: '80%', // Adjust the mobile image width as needed
                height: 'auto', // Maintain aspect ratio
                animation: 'moveRandom 3s infinite ease-in-out', // Animation to move randomly
                '@keyframes moveRandom': {
                  '0%': {
                    transform: 'translateY(0) translateX(0)' // Starting position
                  },
                  '25%': {
                    transform: 'translateY(-15px) translateX(10px)' // Random position
                  },
                  '50%': {
                    transform: 'translateY(20px) translateX(-15px)' // Random position
                  },
                  '75%': {
                    transform: 'translateY(-10px) translateX(5px)' // Random position
                  },
                  '100%': {
                    transform: 'translateY(0) translateX(0)' // End back at the starting position
                  }
                },
                '@media (max-width: 600px)': {
                  // Hide on mobile screens (screen width <= 600px)
                  display: 'none'
                }
              }}
            />

             Canvas */}
            {/* <Canvas
        style={{ height: '600px', width: '1000px' }}
        camera={{ position: [0, 0, 15], fov: 50 }}
      >
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <RotatingSphereWithImages />
      </Canvas> */}
          </Box>
        </Box>
        {/* Features Section */}

        {/* <Box
          sx={{
            background: '#1e293c',
            color: '#ffffff',
            minHeight: '100vh',
            width: '100%'
          }}
        >
          <Container sx={{ padding: '50px 0', background: '#1e293c' }}>
            <Typography
              variant='h4'
              textAlign='center'
              sx={{ marginBottom: '20px' }}
            >
              Features of School Management Software
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  title='Class Management'
                  description='Easily manage classes, schedules, and assignments.'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  title='Exams Management'
                  description='Streamline exam planning and grading with automation.'
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard
                  title='Attendance System'
                  description='Track student and staff attendance efficiently.'
                />
              </Grid>
            </Grid>
          </Container>
        </Box> */}
        <MainComponent  style={{zIndex: 3000, position: "relative"}}/>
        {/* Modal */}
        <Modal
          fullscreen
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={{ ...style, position: 'relative' }}>
            <CloseIcon
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: 16,
                right: 16,
                cursor: 'pointer'
              }}
            />
            <LoginForm />
          </Box>
        </Modal>

        <Modal
          fullscreen
          open={signupOpen}
          onClose={handleSignupClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={{ ...style, position: 'relative', color: 'black' }}>
            <CloseIcon
              onClick={handleSignupClose}
              style={{
                position: 'absolute',
                top: 16,
                right: 16,
                cursor: 'pointer'
              }}
            />
            <SignupForm />
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  )
}

export default Initial
