import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  CardActions,
  CardContent,
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  Avatar
} from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import debounce from 'debounce'
import { useAuth } from '../../services/authContext'
import {
  RegisterApi,
  ForgetPassscodeApi,
  GetUserApi,
  UpdateUserApi,
  FetchDataFromOtpApi
} from '../../services/apis'
import Grid from '@mui/material/Grid'
import { Select, MenuItem } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { BEBase, BEBaseRoot } from '../../constants/app.constant'

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#e4b648' }
    // Add more theme customizations as needed
  }
})

const SignupForm = () => {
  const { loginStatus, setLoginStatus } = useAuth()
  const isUpdate = !!loginStatus?.token

  // State variables for form fields
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [dob, setDob] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [staffType, setStaffType] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [otp, setOtp] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [schoolName, setSchoolName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [currentPasscode, setCurrentPasscode] = React.useState('')
  const [newPasscode, setNewPasscode] = React.useState('')
  const [confirmPasscode, setConfirmPasscode] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [errors, setErrors] = React.useState(false)
  const [profilePicture, setProfilePicture] = React.useState(null)
  const [profilePictureFile, setProfilePictureFile] = React.useState(null)
  const debounceTimeoutRef = useRef(null)

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      setProfilePictureFile(file)
      const reader = new FileReader()
      console.log(profilePictureFile)
      reader.onload = () => {
        setProfilePicture(reader.result) // Display the preview
      }
      reader.readAsDataURL(file)
    }
  }

  React.useEffect(() => {
    if (isUpdate) {
      GetUserApi(loginStatus.user.id)
        .then(response => {
          setPhoneNumber(response.user.phone)
          setUsername(response.user.username)
          setFirstName(response.user.first_name)
          setLastName(response.user.last_name)
          setEmail(response.user.email)
          setAddress(response.user.address)
          setDob(response.user.dob)
          setGender(response.user.gender)
          setStatus(response.user.status)
          setStaffType(response.user.staff_type)
          setPassword(response.user.password)
          setConfirmPassword(response.user.password)
          setSchoolName(response.user.school_name)
          setOtp(response.user.otp)
          setProfilePicture(response.user.profile_picture)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [isUpdate])

  // Debounce function
  const debounceAsync = (func, delay) => {
    let timer
    return (...args) =>
      new Promise(resolve => {
        clearTimeout(timer)
        timer = setTimeout(async () => {
          const result = await func(...args)
          resolve(result)
        }, delay)
      })
  }

  // Debounced API call instance
  const debouncedFetch = debounceAsync(async (value, loginStatus) => {
    return FetchDataFromOtpApi({
      otp: value,
      id: loginStatus.user.id
    })
  }, 500)

  // validateField function
  const validateField = async (field, value) => {
    let error = ''

    const debouncedFetchData = (value, delay) => {
      return new Promise(resolve => {
        if (debounceTimeoutRef.current) {
          clearTimeout(debounceTimeoutRef.current) // Clear existing timeout
        }

        debounceTimeoutRef.current = setTimeout(async () => {
          try {
            const response = await FetchDataFromOtpApi({
              otp: value,
              id: loginStatus.user.id
            })
            resolve(response)
          } catch (error) {
            console.error('Error fetching OTP data:', error)
            resolve({
              detail: 'An error occurred while verifying the passcode.'
            })
          }
        }, delay)
      })
    }

    switch (field) {
      case 'currentPasscode': {
        const response = await debouncedFetchData(value, 500) // 500ms debounce delay
        if (response.detail === 'Current passcode is incorrect.') {
          error = 'Current passcode is incorrect.'
        }
        break
      }
      case 'newPasscode':
        if (!currentPasscode) error = 'Current passcode is required.'
        if (!value) error = 'New passcode is required.'
        else if (!/^[0-9]{6,6}$/.test(value))
          error = 'Passcode should be 6 digits.'
        break

      case 'confirmPasscode':
        if (!value) error = 'Confirm passcode is required.'
        else if (value !== newPasscode)
          error = 'Passcode should match with new passcode.'
        break

      default:
        break
    }

    setErrors(prevErrors => ({ ...prevErrors, [field]: error }))
  }

  // Function to handle signup (creating a new account)
  const handleSignup = async () => {
    const formData = new FormData()
    formData.append('phone', phoneNumber)
    formData.append('username', phoneNumber)
    formData.append('first_name', firstName)
    formData.append('last_name', lastName)
    formData.append('school_name', schoolName)
    formData.append('email', email)
    formData.append('address', address)
    formData.append('date_joined', new Date().toISOString())
    formData.append('dob', dob)
    formData.append('gender', gender)
    formData.append('status', 'active')
    formData.append('staff_type', 'admin')
    formData.append(
      'password',
      firstName.toUpperCase() + lastName.toLowerCase() + Math.random() * 999999
    )
    if (profilePictureFile) {
      formData.append('profile_picture', profilePictureFile) // Ensure profilePictureFile is set from file input
    }

    try {
      if (isUpdate) {
        // Update API call
        formData.delete('staff_type')
        await UpdateUserApi(formData, loginStatus.user.id)
        alert('User updated successfully')
          .then(response => {
            alert('OTP sent successfully!')
            window.location.reload()
          })
          .catch(error => {
            alert('Error sending OTP. Please try again.')
          })
      } else {
        // Register API call
        await RegisterApi(formData)
        await ForgetPassscodeApi({ phone: phoneNumber })
        alert('User registered successfully')
        window.location.reload();
      }
    } catch (error) {
      console.error(error)
      alert('An error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const SignupCard = (
    <React.Fragment>
      <Card
        sx={{
          backgroundColor: 'white',
          boxShadow: 3,
          borderRadius: 2,
          padding: 2
        }}
      >
        <CardContent
          sx={{
            maxHeight: 'calc(100vh - 200px)', // Adjust the height depending on your layout
            overflowY: 'auto', // Enable vertical scrolling
            overflowX: 'hidden', // Hide horizontal overflow
            paddingBottom: '16px',
            backgroundColor: 'white' // Ensure there's some padding at the bottom
          }}
        >
          <Typography
            variant='h5'
            component='h4'
            sx={{
              color: theme.palette.primary.main,
              textAlign: 'center',
              marginBottom: 3,
              fontWeight: 'bold'
            }}
          >
            {isUpdate ? 'Update User' : 'Sign Up to Join the Platform'}
          </Typography>

          <Grid container rowSpacing={2} columnSpacing={3}>
            {' '}
            {/* Responsive column spacing */}
            {/* Phone Number */}
            {!isUpdate && (
              <Grid item xs={12} sm={6}>
                <label
                  style={{
                    marginBottom: '8px',
                    fontWeight: 'bold',
                    color: 'black'
                  }}
                >
                  Phone Number
                </label>
                <TextField
                  variant='outlined'
                  size='small'
                  placeholder='Phone Number'
                  fullWidth
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </Grid>
            )}
            {/* Profile Picture */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ textAlign: 'center', marginBottom: 2 }}
            >
              <Box sx={{ position: 'relative', display: 'inline-block' }}>
                <Avatar
                  src={
                    profilePicture?.startsWith('data:image') // Check if it's a base64 string
                      ? profilePicture // Use as-is
                      : profilePicture
                      ? BEBaseRoot + profilePicture // Use base URL for media path
                      : 'https://via.placeholder.com/150' // Fallback image
                  }
                  alt='Profile Picture'
                  sx={{
                    width: 120,
                    height: 120,
                    margin: '0 auto',
                    border: '2px solid #e4b648'
                  }}
                />
                <IconButton
                  component='label'
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    right: '20%',
                    backgroundColor: '#e4b648',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#d4a238'
                    }
                  }}
                >
                  <CameraAltIcon />
                  <input
                    type='file'
                    hidden
                    accept='image/*'
                    onChange={handleFileChange}
                  />
                </IconButton>
              </Box>
            </Grid>
            {/* First Name */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                First Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='First Name'
                fullWidth
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Last Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Last Name'
                fullWidth
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Grid>
            {/* Email Address */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Email Address
              </label>
              <TextField
                variant='outlined'
                size='small'
                type='email'
                placeholder='Email Address'
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Grid>
            {/* Address */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Address
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='Address'
                fullWidth
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </Grid>
            {/* Date of Birth */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Date of Birth
              </label>
              <TextField
                variant='outlined'
                size='small'
                type='date'
                fullWidth
                value={dob}
                onChange={e => setDob(e.target.value)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {/* Gender */}
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                Gender
              </label>
              <Select
                value={gender}
                onChange={e => setGender(e.target.value)}
                fullWidth
                size='small'
                sx={{ backgroundColor: 'white' }}
              >
                {/* Placeholder as default option */}
                <MenuItem value='' disabled>
                  Select Gender
                </MenuItem>

                {/* Gender Options */}
                <MenuItem style={{ color: 'black' }} value='male'>
                  Male
                </MenuItem>
                <MenuItem style={{ color: 'black' }} value='female'>
                  Female
                </MenuItem>
                <MenuItem style={{ color: 'black' }} value='others'>
                  Others
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <label
                style={{
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  color: 'black'
                }}
              >
                School Name
              </label>
              <TextField
                variant='outlined'
                size='small'
                placeholder='School Name'
                style={{
                  color: 'black', // For regular text color
                  backgroundColor: 'white' // Ensure contrast
                }}
                fullWidth
                value={schoolName}
                onChange={e => setSchoolName(e.target.value)}
              />
            </Grid>
            {isUpdate && (
              <Box
                sx={{
                  width: '100%',
                  padding: '16px',
                  backgroundColor: '#ffffff'
                }}
              >
                <hr />
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: 'bold',
                    color: '#e4b648',
                    marginBottom: '16px',
                    textAlign: 'center' // Center-align the header text
                  }}
                >
                  Passcode Change
                </Typography>
                {/* Current Passcode */}
                <Box sx={{ marginBottom: '16px' }}>
                  <label
                    style={{
                      marginBottom: '8px',
                      fontWeight: 'bold',
                      color: 'black',
                      display: 'block'
                    }}
                  >
                    Current Passcode
                  </label>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='Enter your current passcode'
                    fullWidth
                    value={currentPasscode}
                    onChange={e => {
                      setCurrentPasscode(e.target.value)
                      validateField('currentPasscode', e.target.value)
                    }}
                    error={!!errors.currentPasscode}
                    helperText={errors.currentPasscode}
                  />
                </Box>

                {/* New Passcode */}
                <Box sx={{ marginBottom: '16px' }}>
                  <label
                    style={{
                      marginBottom: '8px',
                      fontWeight: 'bold',
                      color: 'black',
                      display: 'block'
                    }}
                  >
                    New Passcode
                  </label>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='Enter your new passcode'
                    fullWidth
                    value={newPasscode}
                    onChange={e => {
                      setNewPasscode(e.target.value)
                      validateField('newPasscode', e.target.value)
                    }}
                    error={!!errors.newPasscode}
                    helperText={errors.newPasscode}
                  />
                </Box>

                {/* Confirm Passcode */}
                <Box>
                  <label
                    style={{
                      marginBottom: '8px',
                      fontWeight: 'bold',
                      color: 'black',
                      display: 'block'
                    }}
                  >
                    Confirm New Passcode
                  </label>
                  <TextField
                    variant='outlined'
                    size='small'
                    placeholder='Confirm your new passcode'
                    fullWidth
                    value={confirmPasscode}
                    onChange={e => setConfirmPasscode(e.target.value)}
                    error={!!confirmPasscode && confirmPasscode !== newPasscode}
                    helperText={
                      !!confirmPasscode && confirmPasscode !== newPasscode
                        ? 'Confirm Passcode should match with New Passcode.'
                        : ''
                    }
                  />
                </Box>
              </Box>
            )}
          </Grid>

          {/* Signup Form Submit Button */}
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSignup}
              disabled={loading}
              sx={{
                color: 'black', // Set the text color to black
                bgcolor: '#eda811', // Set the background color
                '&:hover': {
                  bgcolor: 'grey' // Set the background color to grey on hover
                }
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : isUpdate ? (
                'Update'
              ) : (
                'Sign Up'
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  )

  return (
    <ThemeProvider theme={theme}>
      <div className='SignupForm' data-testid='SignupForm'>
        <Box
          sx={{
            minWidth: 275,
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
          className='perfect-center'
        >
          <Card variant='outlined'>{SignupCard}</Card>
        </Box>
      </div>
    </ThemeProvider>
  )
}

SignupForm.propTypes = {}

export default SignupForm
