import React, { useState, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Typography, Grid, Chip } from "@mui/material";
import TopBackBar from "./TopBackBar";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#284d84",
    },
    dark: {
      main: "#111111",
    },
    background: {
      default: "#081727",
      paper: "#081727",
      warning: "#f5c44e",
    },
  },
});

const LayoutWithBackButton = ({ title = "Little Steps", to = "/" }) => {
  const [loginStatus, setLoginStatus] = useState("");
  const [init, setInit] = useState(false);

  useEffect(() => {
    const loginStat = localStorage.getItem("course");
    if (loginStat) {
      setLoginStatus(loginStat);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          paddingTop: { xs: "80px", md: "60px" },
          paddingBottom: { xs: "80px", md: "80px" },
        }}>
        <TopBackBar title={title} to={to} />
        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default LayoutWithBackButton;
