import  secureLocalStorage  from  "react-secure-storage";

function generateRandomString() {
    console.log('key')
    const characters = secureLocalStorage.getItem("key");
    return characters;
}

// Dynamically generated value
export const efefrg4rrrg323ffgfssw3e3ddadfw23 = generateRandomString(); // Adjust length as needed
