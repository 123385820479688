import React from 'react'
import './Home.scss'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { LoginApi } from '../../services/apis'
import { useAuth } from '../../services/authContext' // Import the custom hook
import { Grid2, Container } from '@mui/material'
import Status from '../Status/Status'
import Snackbar from '@mui/material/Snackbar'
import Initial from '../Initial/Initial'
import AdminDashBoard from '../AdminDashBoard/AdminDashBoard';
import TeacherDashboard from '../TeacherDashboard/TeacherDashboard';
import StudentDashboard from '../StudentDashboard/StudentDashboard';

const Home = () => {
  const { loginStatus, setLoginStatus } = useAuth() // Use the context
  const [username, setUserName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [myGreeting, setMyGreeting] = React.useState('Hello')

  const userNameHandler = e => {
    setUserName(e.target.value)
  }

  const passwordHandler = e => {
    setPassword(e.target.value)
  }

  return (
    <Box
      className='perfect-center'
      sx={{
        background: !loginStatus.token
          ? 'linear-gradient(to top, rgb(13, 34, 56), rgb(8, 23, 39));'
          : '',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom left'
      }}
    >
      <div className='mainlogo perfect-center'>
        {loginStatus.token ? (
          <>
            {loginStatus.user.staff_type === 'admin' ? (
              <AdminDashBoard loginDetails={loginStatus} />
            ) : loginStatus.user.staff_type === 'student' ? (
              <StudentDashboard loginDetails={loginStatus} />
            ) : (
              <TeacherDashboard loginDetails={loginStatus} />
            )}
          </>
        ) : (
          <Initial />
        )}
      </div>
    </Box>
  );
};

export default Home
