import React, { useEffect, useState } from "react";
import { Page, Text, View, Document, PDFDownloadLink, Image, StyleSheet } from "@react-pdf/renderer";
import QRCode from "qrcode";
import { BEBaseRoot } from "../../constants/app.constant";
import { Button } from "@mui/material";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: 3,
    fontFamily: "Helvetica",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    backgroundColor: "#004aad",
    color: "#ffffff",
    padding: 4,
    textAlign: "center",
    fontSize: 9,
    marginBottom: 3,
  },
  contentBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: "#f9f9f9",
    padding: 3,
  },
  profileContainer: {
    alignItems: 'center',
    marginBottom: 3,
    backgroundColor: '#fff',
    padding: 2,
    borderRadius: 2,
  },
  profilePicture: {
    width: 45,
    height: 45,
    margin: "auto",
  },
  detailsContainer: {
    flex: 1,
    backgroundColor: "#ffffff",
    padding: 3,
    marginBottom: 3,
    borderRadius: 2,
  },
  fieldText: {
    fontSize: 7,
    marginBottom: 1.5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    color: "#004aad",
    width: '30%',
  },
  value: {
    width: '70%',
    textAlign: 'left',
  },
  bottomSection: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
  },
  qrCodeContainer: {
    backgroundColor: '#ffffff',
    padding: 3,
    alignItems: 'center',
    borderRadius: 2,
  },
  qrCode: {
    width: 35,
    height: 35,
  },
  footer: {
    fontSize: 5,
    color: "#666",
    textAlign: "center",
    backgroundColor: '#ffffff',
    padding: 2,
    width: '100%',
    borderRadius: 2,
  },
  divider: {
    height: 1,
    backgroundColor: '#e0e0e0',
    marginVertical: 2,
  },
});

const IDCardPDF = ({ studentData, schoolName }) => {
  const [qrCodeImage, setQrCodeImage] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const qrCodeData = JSON.stringify({
          name: `${studentData.first_name || ""} ${studentData.last_name || ""}`,
          class: studentData.student_class || "",
          rollNo: studentData.student_roll_no || "",
          phone: studentData.phone || "",
          address: studentData.address || "",
        });

        const url = await QRCode.toDataURL(qrCodeData, {
          width: 60,
          margin: 1,
          errorCorrectionLevel: 'L'
        });
        setQrCodeImage(url);
      } catch (err) {
        console.error("QR Code generation failed:", err);
      }
    };

    generateQRCode();
  }, [studentData]);

  useEffect(() => {
    const loadProfileImage = async () => {
      if (!studentData.profile_picture) return;

      try {
        const imgUrl = studentData.profile_picture.startsWith("data:image")
          ? studentData.profile_picture
          : `${BEBaseRoot}${studentData.profile_picture}`;

        // For external images, fetch and convert to base64
        if (!studentData.profile_picture.startsWith("data:image")) {
          const response = await fetch(imgUrl);
          const blob = await response.blob();
          const reader = new FileReader();
          
          reader.onloadend = () => {
            setProfileImage(reader.result);
          };
          
          reader.readAsDataURL(blob);
        } else {
          setProfileImage(imgUrl);
        }
      } catch (error) {
        console.error("Error loading profile image:", error);
        setProfileImage(null);
      }
    };

    loadProfileImage();
  }, [studentData.profile_picture]);

  return (
    <Document>
      <Page size="A7" style={styles.page}>
        <View style={styles.header}>
          <Text>{schoolName ? schoolName.toUpperCase() : "SCHOOL NAME"}</Text>
        </View>

        <View style={styles.contentBox}>
          <View style={styles.profileContainer}>
            {profileImage && (
              <Image
                src={profileImage}
                style={styles.profilePicture}
                cache={false}
              />
            )}
          </View>

          <View style={styles.detailsContainer}>
            {[
              { label: "Name", value: `${studentData.first_name || ""} ${studentData.last_name || ""}` },
              { label: "Class", value: studentData.student_class || "" },
              { label: "Roll No", value: studentData.student_roll_no || "" },
              { label: "Phone", value: studentData.phone || "" },
              { label: "Address", value: studentData.address || "" },
            ].map((field, index) => (
              <View key={index}>
                <Text style={styles.fieldText}>
                  <Text style={styles.label}>{field.label}:</Text>
                  <Text style={styles.value}>{field.value || "N/A"}</Text>
                </Text>
                {index < 4 && <View style={styles.divider} />}
              </View>
            ))}
          </View>

          <View style={styles.bottomSection}>
            {qrCodeImage && (
              <View style={styles.qrCodeContainer}>
                <Image src={qrCodeImage} style={styles.qrCode} />
              </View>
            )}
            
            <View style={styles.footer}>
              <Text>School Property • Valid until graduation</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const IDCardGenerator = ({ studentData, schoolName }) => {
  return (
    <PDFDownloadLink
      document={<IDCardPDF studentData={studentData} schoolName={schoolName} />}
      fileName={`Student_ID_${studentData.first_name || "Unknown"}_${studentData.last_name || "Unknown"}_${studentData.student_roll_no || "N/A"}.pdf`}
      style={{ textDecoration: 'none' }}
    >
      {({ blob, url, loading, error }) => {
        if (error) {
          console.error("Error downloading PDF:", error);
        }
        return loading ? (
          "Generating document..."
        ) : (
          <Button variant="contained" color="success">
            ID Card
          </Button>
        );
      }}
    </PDFDownloadLink>
  );
};

export default IDCardGenerator;
