import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../../services/authContext';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Container,
  Fab,
  Card,
  CardMedia,
  Button,
  Modal,
  Fade,
  Grid,
  Popover,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BEBase } from '../../constants/app.constant';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CreateTopImageApi, deleteImageApi, ListTopImageApi, DeleteTopImageApi } from '../../services/apis'; // Import the upload and delete image APIs
import CloseIcon from '@mui/icons-material/Close';

const theme = createTheme({
  palette: {
    primary: { main: '#284d84' }, // Blue color
    secondary: { main: '#EC4899' }, // Pink color
  },
  typography: { fontFamily: 'Arial, sans-serif' },
});

const ImgStyle = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%", // Default width
  bgcolor: '#ffffff',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#e4b648',
  overflow: 'auto',
  marginTop: '190px',
  '@media (max-width: 600px)': { // Adjust the breakpoint as needed
    width: '100%', // Width for mobile devices
  }
};

const TopImages = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [showText, setShowText] = useState(true); // New state to control visibility
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { loginStatus, setLoginStatus } = useAuth();
  const [listOfUsers, setListOfUsers] = React.useState([]);
  const [selectedID, setSelectedId] = React.useState(0);
  const [deleteProductOpen, setDeleteProductOpen] = React.useState(false);
  const [listApiFlag, setListApiFlag] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (loginStatus && listApiFlag && loginStatus.user && loginStatus.user.is_superuser) {
      const fetchListProducts = async () => {
        try {
          const response = await ListTopImageApi(JSON.stringify({
            id: JSON.parse(loginStatus.user.id)
          }));
          setListOfUsers(response);
          setListApiFlag(false);
        } catch (error) {
          console.error('Error fetching users:', error);
          setListApiFlag(false);
        }
      };

      fetchListProducts();
    }
  }, [loginStatus, listApiFlag]);

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleFabClick = (event) => {
    setPopoverAnchor(event.currentTarget);
  };

  const handleCameraClick = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Check if an existing video element already exists
        let videoElement = document.getElementById('cameraStream');
        if (!videoElement) {
          // Create a new video element
          videoElement = document.createElement('video');
          videoElement.id = 'cameraStream';
          videoElement.style.position = 'fixed';
          videoElement.style.bottom = '10px';
          videoElement.style.right = '10px';
          videoElement.style.width = '300px';
          videoElement.style.zIndex = '1000';
          document.body.appendChild(videoElement);
        }

        videoElement.srcObject = stream;
        videoElement.play();
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });

    // Close the popover (assuming `setPopoverAnchor` manages its visibility)
    setPopoverAnchor(null);
  };


  const handleUploadClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
    setPopoverAnchor(null);
  };

  const handleImageSelect = async (e) => {
    const formData = new FormData()

    // Append text fields
    formData.append('imagePic1', e.target.files[0])
    formData.append('id', loginStatus.user.id)
    if (formData) {
      try {
        // Call the API to upload the image
        const response = await CreateTopImageApi(formData);
        //console.log('Top Image created successfully:', response)
        window.location.reload()
        //handleClose()
      } catch (error) {
        console.error('Error creating Top Image:', error)
      }
    };
  }

  const handleDeleteImage = async (imageToDelete) => {
    try {
      const formData = new FormData();
      formData.append('id', selectedID);

      // Assuming DeleteCourseApi expects formData
      const response = await DeleteTopImageApi(formData);

      console.log("Product deleted successfully:", response);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting Product:", error);
    }
  };

  const handleModalOpen = (image) => {
    setSelectedImage(image);
    setOpenModal(true);
  };


  const handleModalClose = () => {
    setSelectedImage(null);
    setOpenModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
     
    
        <Container
               sx={{
                 flexGrow: 1,
                 mt: { xs: 6, md: 10 },
                 mb: { xs: 5, md: 7 }, 
                 paddingBottom: { xs: '80px', md: '80px' }
                 // bgcolor: 'background.paper'
               }}
             >
          <Grid container spacing={2} justifyContent="center">
            {listOfUsers.map((imageUrl, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{ position: 'relative', cursor: 'pointer' }}
                  onClick={() => handleModalOpen(imageUrl)}
                >
                  <CardMedia component="img" image={BEBase + 'media/' + imageUrl.imagePic1 + `?_=${new Date().getTime()}`} alt={`Uploaded Image ${index + 1}`} sx={{ height: 200, objectFit: 'cover' }} />

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedId(imageUrl.id);
                      setDeleteProductOpen(true);
                    }}
                    sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white' }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Modal
            fullscreen
            open={deleteProductOpen}
            onClose={() => setDeleteProductOpen(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={{ ...ImgStyle, position: 'relative' }}>
              <CloseIcon
                onClick={() => setDeleteProductOpen(false)}
                style={{
                  position: 'absolute',
                  top: 16,
                  right: 16,
                  cursor: 'pointer'
                }}
              />
              <h3>Are you Sure You want to delete this Top Image</h3>
              <Button
                variant='contained'
                className='btn'
                onClick={() => {
                  handleDeleteImage()
                }}
              >
                Yes
              </Button>
              <Button
                variant='contained'
                className='btn'
                onClick={() => setDeleteProductOpen(false)}
              >
                No
              </Button>
            </Box>
          </Modal>

          <Modal open={openModal} onClose={handleModalClose} closeAfterTransition>
            <Fade in={openModal}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  bgcolor: 'background.paper',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                {selectedImage && (
                  <CardMedia
                    component="img"
                    image={selectedImage}
                    alt="Selected Image"
                    sx={{ width: '100%', height: 'auto' }}
                  />
                )}
              </Box>
            </Fade>
          </Modal>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleImageSelect}
          />

          <Fab
            color="primary"
            sx={{ position: 'fixed', bottom: 76, right: 26 }}
            onClick={handleFabClick}
          >
            <AddIcon />
          </Fab>

          <Popover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={() => setPopoverAnchor(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CameraAltIcon />}
                onClick={handleCameraClick}
                sx={{ mb: 1 }}
              >
                Open Camera
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CloudUploadIcon />}
                onClick={handleUploadClick}
              >
                Upload Picture
              </Button>
            </Box>
          </Popover>
        </Container>

    </ThemeProvider >
  );
};

TopImages.propTypes = {};
TopImages.defaultProps = {};

export default TopImages;
