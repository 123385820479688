import React from 'react';
import PropTypes from 'prop-types';
import './Terms.scss';
import { useAuth } from '../../services/authContext';

const Terms = () => {
  const { loginStatus, setLoginStatus } = useAuth();

  return (
    <div className="Terms" data-testid="Terms">
      <h1>Terms and Conditions for Xtute Hello Client App</h1>

      <p>Welcome to the Xtute Hello Client App (the "App"), provided by Xtute Technologies Pvt Ltd ("Xtute," "we," "us," or "our"). By using the App, you agree to comply with and be bound by these Terms and Conditions ("Terms"). Please read them carefully.</p>

      <h2>1. Acceptance of Terms</h2>
      <p>By downloading, accessing, or using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.</p>

      <h2>2. Eligibility</h2>
      <p>You must be at least 18 years old to use this App. By using the App, you represent and warrant that you meet this eligibility requirement.</p>

      <h2>3. Account Registration</h2>
      <p>To access certain features of the App, you may be required to create an account. You agree to:</p>
      <ul>
          <li>Provide accurate, current, and complete information during the registration process.</li>
          <li>Maintain the security of your password and account.</li>
          <li>Notify us immediately of any unauthorized use of your account.</li>
          <li>Be responsible for all activities that occur under your account.</li>
      </ul>

      <h2>4. Payment Transactions</h2>
      <p>The App facilitates payment transactions between clients and service providers. By using the App, you agree to:</p>
      <ul>
          <li>Provide accurate payment information.</li>
          <li>Ensure sufficient funds or credit to complete the transaction.</li>
          <li>Abide by the payment terms and conditions provided by your payment service provider.</li>
      </ul>

      <h2>5. Task Management</h2>
      <p>The App allows clients to assign tasks to service providers. You agree to:</p>
      <ul>
          <li>Clearly communicate task details and expectations.</li>
          <li>Respect deadlines and commitments made through the App.</li>
          <li>Provide feedback and ratings based on the quality of services received.</li>
      </ul>

      <h2>6. User Responsibilities</h2>
      <p>You are solely responsible for your use of the App and for any content you submit or share. You agree not to:</p>
      <ul>
          <li>Use the App for any illegal or unauthorized purpose.</li>
          <li>Harass, abuse, or harm other users.</li>
          <li>Upload or share any content that is offensive, defamatory, or violates any third-party rights.</li>
      </ul>

      <h2>7. Intellectual Property</h2>
      <p>All content and materials within the App, including but not limited to text, graphics, logos, and software, are the property of Xtute Technologies Pvt Ltd and are protected by copyright and intellectual property laws. You may not reproduce, distribute, or create derivative works without our express written consent.</p>

      <h2>8. Privacy Policy</h2>
      <p>Your use of the App is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information. By using the App, you consent to the practices described in the Privacy Policy.</p>

      <h2>9. Termination</h2>
      <p>We reserve the right to suspend or terminate your access to the App at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users or the App.</p>

      <h2>10. Disclaimer of Warranties</h2>
      <p>The App is provided on an "as-is" and "as-available" basis. We make no warranties, express or implied, regarding the App's availability, reliability, or suitability for a particular purpose.</p>

      <h2>11. Limitation of Liability</h2>
      <p>To the fullest extent permitted by law, Xtute Technologies Pvt Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of your use of the App.</p>

      <h2>12. Changes to Terms</h2>
      <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on the App. Your continued use of the App after the posting of changes constitutes your acceptance of those changes.</p>

      <h2>13. Governing Law</h2>
      <p>These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>

      <h2>14. Contact Us</h2>
      <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:info@xtute.com">info@xtute.com</a>.</p>

      <p>By using the Xtute Hello Client App, you acknowledge that you have read and agree to these Terms and Conditions.</p>

    </div>
  );
};

export default Terms;
